<template>
  <el-dialog
    :close-on-click-modal="false"
    v-model="dialogFormVisible"
    title="行政许可文件"
    width="1000px"
    destroy-on-close
  >
    <div class="top_select">
      <el-button @click="add" type="primary" size="small" plain icon="Plus"
        >添加</el-button
      >
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      v-loading="loading"
      :height="contentStyleObj"
    >
      <el-table-column type="index" width="50" label="序号" align="center" />
      <el-table-column
        prop="name"
        label="行政许可文件名称(大类)"
        min-width="100px"
      />
      <el-table-column
        prop="nameItem"
        label="行政许可文件名称(小类)"
        min-width="200px"
      />
      <el-table-column
        prop="expirationDate"
        label="有效期截止日期"
        width="120px"
      />
      <el-table-column prop="name" label="操作" width="150" align="center">
        <template #default="scope">
          <el-button
            @click="handleAdd(scope.row)"
            type="primary"
            size="small"
            plain
            >修改</el-button
          >
          <el-button @click="del(scope.row)" type="danger" size="small" plain
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <template #footer>
      <div class="dialog-footer">
        <el-button
          @click="dialogFormVisible = false"
          size="small"
          type="primary"
          >确定</el-button
        >
      </div>
    </template>
  </el-dialog>
  <el-dialog
    :close-on-click-modal="false"
    v-model="dialogFormVisibleAdd"
    title="文件信息"
    width="1000px"
    destroy-on-close
  >
    <el-row>
      <el-col :span="5">
        行政许可文件名称
      </el-col>
      <el-col :span="19">
        <el-select
          size="small"
          v-model="formInline.name"
          placeholder="请选择"
          clearable
          filterable
          @change="handleChange"
          style="width:40%;margin-left: 0;"
        >
          <el-option
            v-for="item in types"
            :key="item.name"
            :label="item.name"
            :value="item.name"
          ></el-option>
        </el-select>
        <el-select
          size="small"
          v-model="formInline.nameItem"
          placeholder="请选择"
          clearable
          filterable
          style="width:56%"
          v-if="formInline.name"
        >
          <el-option
            v-for="item in typeOption"
            :key="item.name"
            :label="item.name"
            :value="item.name"
          ></el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="5">
        有效期截止日期
      </el-col>
      <el-col :span="19">
        <el-date-picker
          size="small"
          value-format="YYYY-MM-DD"
          v-model="formInline.expirationDate"
          type="date"
          placeholder="有效期截止日期"
        >
        </el-date-picker>
      </el-col>
    </el-row>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="dialogFormVisibleAdd = false" size="small"
          >取消</el-button
        >
        <el-button type="primary" @click="save" size="small">
          保存
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { getCompanyInfo, updateGsnb, deleteGsnb } from "@/api/gsnb";
export default {
  name: "EtaxWebV3GdjczxxTable",
  props: {
    startAccountPeriod: {
      default: "",
      type: String,
    },
    comId: {
      default: 0,
      type: Number,
    },
  },
  data() {
    return {
      dialogFormVisible: false,
      dialogFormVisibleAdd: false,
      tableData: [],
      formInline: {},
      contentStyleObj: {},
      loading: false,
      types: [
        { name: "农业" },
        { name: "采矿业" },
        { name: "食品、烟草" },
        { name: "供电、供水" },
        { name: "交通、邮电" },
        { name: "特行" },
        { name: "医疗器械、农药、兽药" },
        { name: "金融" },
        { name: "信息传输" },
        { name: "文化" },
        { name: "维修、制造" },
        { name: "政府部门主管、国务院" },
        { name: "地方政府批文" },
        { name: "其他" },
      ],
      typeOption: [],
    };
  },

  mounted() {
    this.contentStyleObj = this.$getHeight(300);
  },

  methods: {
    getParams() {
      this.dialogFormVisible = true;
      this.getList();
    },
    getList() {
      this.loading = true;
      getCompanyInfo({
        comId: this.comId,
        year: this.startAccountPeriod,
        tableName: "gsnb_administration",
      }).then((res) => {
        this.loading = false;
        if (res.data.msg == "success") {
          this.tableData = res.data.data.data;
        }
      });
    },
    handleAdd(row) {
      this.formInline = Object.assign({}, row);
      this.filterType();
      this.dialogFormVisibleAdd = true;
    },
    add() {
      this.formInline = {};
      this.dialogFormVisibleAdd = true;
    },
    save() {
      if (!this.formInline.name || !this.formInline.expirationDate) {
        this.$qzfMessage("请填写完整信息", 1);
        return;
      }
      this.formInline.comId = this.comId;
      this.formInline.year = this.startAccountPeriod;
      let param = {
        tableName: "gsnb_administration",
        query: this.formInline,
      };
      updateGsnb(param).then((res) => {
        if (res.data.msg == "success") {
          this.$qzfMessage("保存成功");
          this.dialogFormVisibleAdd = false;
          this.getList();
        }
      });
    },
    del(row) {
      this.$confirm("确认删除该条信息吗？", "提示", {
        confirmButtonText: "确定",
        cancleButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteGsnb({ ids: [row.id], tableName: "gsnb_administration" }).then(
            (res) => {
              if (res.data.msg == "success") {
                this.$qzfMessage("成功删除");
                this.getList();
              }
            }
          );
        })
        .catch(() => {});
    },
    filterType() {
      if (this.formInline.name == "农业") {
        this.typeOption = [
          { name: "种子生产许可证" },
          { name: "转基因种子、种畜禽、水产苗种生产许可证" },
          { name: "缫丝、绢纺生产准产证" },
          { name: "饲料和饲料添加剂企业生产许可证" },
          { name: "蚕种经营资格证书" },
          { name: "粮食收购资格许可证" },
          { name: "蚕种生产资格证书" },
          { name: "农业转基因生产许可证" },
          { name: "渔业管理许可证" },
          { name: "种子经营许可证" },
          { name: "驯养繁殖许可证" },
          { name: "化肥农膜专营审批文件" },
          { name: "生猪屠宰资质证书" },
          { name: "鲜茧收购资格证书" },
          { name: "棉花收购、加工资格证书" },
          { name: "种畜禽生产经营许可证" },
          { name: "农业转基因经营许可证" },
        ];
      } else if (this.formInline.name == "采矿业") {
        this.typeOption = [
          { name: "矿山企业安全生产许可证" },
          { name: "勘察许可证" },
          { name: "采矿许可证" },
          { name: "煤炭生产许可证" },
          { name: "煤炭经营资格证书" },
          { name: "开采黄金矿产批准证书" },
        ];
      } else if (this.formInline.name == "食品、烟草") {
        this.typeOption = [
          { name: "化妆品生产企业卫生许可证" },
          { name: "特种烟草专卖经营许可证" },
          { name: "烟花爆竹销售许可证" },
          { name: "食品流通许可证" },
          { name: "烟草批发许可证" },
          { name: "烟草零售许可证" },
          { name: "烟花爆竹安全生产许可证" },
          { name: "食盐许可证(生产许可证)" },
          { name: "成品油仓储经营批准证书" },
          { name: "烟草业生产企业许可证" },
          { name: "食盐批发许可证" },
          { name: "成品油零售经营批准证书" },
        ];
      } else if (this.formInline.name == "供电、供水") {
        this.typeOption = [
          { name: "取水许可证" },
          { name: "城市供水企业资质证书" },
          { name: "供电营业许可证" },
        ];
      } else if (this.formInline.name == "交通、邮电") {
        this.typeOption = [
          { name: "烟草专卖品准运证" },
          { name: "木材运输证" },
          { name: "空运销售代理业务经营批准证书" },
          { name: "通用航空经营许可证" },
          { name: "国际货物运输代理批准证书" },
          { name: "道路运输经营许可证" },
          { name: "国际船舶运输经营许可证" },
          { name: "公共航空运输企业经营许可证" },
          { name: "水路运输服务许可证" },
          { name: "爆炸物品运输证" },
          { name: "水路运输许可证" },
          { name: "剧毒化学品公路运输通行证" },
          { name: "邮电专营许可证" },
        ];
      } else if (this.formInline.name == "特行") {
        this.typeOption = [
          { name: "危险废物经营许可证" },
          { name: "废旧金属收购特行许可证" },
          { name: "特行许可证" },
          { name: "化学危险品经营许可证" },
          { name: "民用爆炸物品销售许可证" },
          { name: "爆炸物品销售许可证" },
          { name: "民用爆炸物品生产许可证" },
          { name: "化学危险品生产许可证" },
          { name: "爆破作业单位许可证" },
          { name: "化学试剂生产许可证" },
          { name: "食用品化工产品生产许可证" },
          { name: "拍卖业特行许可证" },
          { name: "民用爆破器材生产许可证" },
          { name: "民用爆破器材销售许可证" },
          { name: "境外可利用废物的进口经营许可证" },
          { name: "爆炸物品安全生产许可证" },
        ];
      } else if (this.formInline.name == "医疗器械、农药、兽药") {
        this.typeOption = [
          { name: "动物诊疗许可证" },
          { name: "药品(中药材)经营企业许可证" },
          { name: "文物保护工程资质证书" },
          { name: "药品(中药材)经营企业合格证" },
          { name: "文物出境许可证" },
          { name: "文物拍卖许可证" },
          { name: "医疗机构制剂许可证" },
          { name: "放射性药品经营许可证" },
          { name: "医疗机构执业许可证" },
          { name: "医疗器械生产许可证" },
          { name: "医疗器械经营许可证" },
          { name: "兽药经营许可证" },
          { name: "药品生产许可证" },
          { name: "药品经营许可证" },
          { name: "农药经营许可证" },
          { name: "放射性药品生产许可证" },
          { name: "兽药生产许可证" },
          { name: "农药生产许可证" },
        ];
      } else if (this.formInline.name == "金融") {
        this.typeOption = [
          { name: "金融机构法人许可证" },
          { name: "保险业务许可证" },
          { name: "信托机构法人许可证" },
          { name: "期货经纪业务许可证" },
          { name: "境外期货业务许可证" },
          { name: "证券业务许可证" },
          { name: "典当特行许可证" },
          { name: "金融机构营业许可证" },
          { name: "典当经营许可证" },
          { name: "经营保险业务许可证" },
          { name: "金融机构法人许可证" },
          { name: "经营外汇业务许可证" },
          { name: "经营保险公司评估业务许可证" },
          { name: "基金管理公司法人许可证" },
        ];
      } else if (this.formInline.name == "信息传输") {
        this.typeOption = [
          { name: "互联网上网服务营业场所许可证" },
          { name: "经营性互联网信息服务许可证" },
          { name: "电信企业许可证" },
          { name: "电信业务经营许可证" },
          { name: "互联网信息服务增值电信业务经营许可证" },
        ];
      } else if (this.formInline.name == "文化") {
        this.typeOption = [
          { name: "货物和技术进出口对外贸易许可证" },
          { name: "电子游戏经营场所经营许可证" },
          { name: "有线电视工程设计、安装许可证" },
          { name: "营业性演出许可证" },
          { name: "广播电视节目及电视剧制作许可证" },
          { name: "电子出版物出版、复制许可证" },
          { name: "电子出版物发行许可证" },
          { name: "音像制品零售、出租许可证" },
          { name: "音像制品放映许可证" },
          { name: "音像制品批发经营许可证" },
          { name: "外商投资广告项目审批意见书" },
          { name: "广告经营许可证" },
          { name: "网络文化经营许可证" },
          { name: "中外合作摄制电影片许可证" },
          { name: "娱乐经营许可证" },
          { name: "出版物经营许可证" },
          { name: "印刷业特行许可证" },
          { name: "发行出版许可证" },
          { name: "出版物进口经营许可证" },
          { name: "音像制品出版经营许可证" },
          { name: "音像制品复制许可证" },
          { name: "摄制电影许可证" },
          { name: "电影发行经营许可证" },
          { name: "电影放映经营许可证" },
          { name: "印刷经营许可证" },
          { name: "文化经营许可证" },
        ];
      } else if (this.formInline.name == "维修、制造") {
        this.typeOption = [
          { name: "锅炉压力容器安全监察部门许可证" },
          { name: "计量器具制造许可证" },
          { name: "金制品业务许可证" },
          { name: "汽车维修技术台格证书" },
          { name: "农业机械维修技术合格证书" },
          { name: "枪支制造、销售许可证" },
          { name: "计量器具修理许可证" },
          { name: "特种刀具生产、销售许可证" },
          { name: "无线电管制器材生产许可证" },
          { name: "报废汽车回收资格认定书" },
          { name: "报废汽车回收特行许可证" },
          { name: "治安许可证" },
          { name: "测绘资格证书" },
          { name: "特种设备许可证" },
        ];
      } else if (this.formInline.name == "政府主管部门、国务院") {
        this.typeOption = [
          { name: "国务院批文" },
          { name: "国家授权单位批文" },
          { name: "邮政管理部门批文" },
          { name: "工商行政管理部门批文" },
          { name: "国务院盐业主管机构定点生产批文" },
          { name: "省级以上建设部门资质审查" },
          { name: "国务院盐业主管机构碘盐加工批文" },
          { name: "省级以上政法委员会批文" },
          { name: "国务院经济贸易主管部门会同有关部门批文。" },
          { name: "省级以上财政部门批文" },
          { name: "国务院证券管理部门和国务院经济贸易管理部门联合行文批复" },
          { name: "省级以上饲料主管部门批文" },
          { name: "国务院证券管理部门批文" },
          { name: "国家计委批文" },
          { name: "国务院外经贸主管部门批文" },
          { name: "国务院有色金属主管部门批文" },
          { name: "国务院交通主管部门登记文" },
          { name: "国务院兵器工业主管部门批文" },
          { name: "国务院卫生行政部门会同国务院药品监督管理部门批文" },
          { name: "国务院外经贸主管部门及基受托机构审查批准" },
          { name: "信息产业部批文" },
          { name: "国务院卫生行政部门批文" },
          { name: "财政部批文" },
          { name: "国务院药品监督管理部门指定文" },
          { name: "国家工商行政管理总局负责批文" },
          { name: "国务院出版行政部门批文" },
          { name: "国务院新闻出版行政部门批文" },
          { name: "国务院纺织工业主管部门批文" },
          { name: "国务院计划管理部门批文" },
          { name: "公安部门核发旅馆业批文" },
          { name: "交通主管部门签注意见" },
          { name: "国务院经济贸易主管部门会同有关部门批文" },
          { name: "海关总署批文" },
          { name: "商民航总局批文" },
          { name: "药品监督管理部门批文" },
          { name: "经营政府批文" },
          { name: "林业主管部门批文" },
          { name: "司法行政主管部门批准改制报告" },
          { name: "建设部门核发生产许可证" },
          { name: "商业流通主管部门会同农牧等主管部门批文" },
          { name: "公共交通客运管理部门许可证" },
          { name: "建设部门核发安装维修资质证书" },
          { name: "政府主管部门批文" },
          { name: "中央组织部批文" },
          { name: "国家外专局批文" },
          { name: "国家文物局批文" },
          { name: "国家密码管理机构实行定点生产销售" },
          { name: "林业主管部门批文" },
          { name: "商业单位的外轮供应公司和中国远洋(集团)总公司专营" },
          { name: "国家税务局批文" },
          { name: "国务院版权管理部门批文" },
          { name: "国家专利局批文" },
          { name: "国家工商总局批文" },
          { name: "房地产管理部门批文" },
          { name: "政府人事部门批文" },
          { name: "环保主管部门批文" },
          { name: "化学工业主管部门批文" },
          { name: "中华人民共和国基础电信业务经营许可证" },
          { name: "园林主管部门批文" },
          { name: "中华人民共和国增值电信业务经营许可证" },
          { name: "绿化行政主管部门批文" },
          { name: "林木采伐许可证" },
          { name: "民政部门批文" },
          { name: "公安部门批文" },
          { name: "公安部门许可证" },
          { name: "客运管理机构批文" },
        ];
      } else if (this.formInline.name == "政府批文") {
        this.typeOption = [
          { name: "县级以上公安、文化部门批文" },
          { name: "省级以上科技主管部门批文" },
          { name: "省级以上国有资产管理部门批文" },
          { name: "省级以上文物主管部门批文" },
          { name: "计划单列市以上财政部门批文" },
          { name: "省级环保部门批文" },
          { name: "省级公安部门登记" },
          { name: "省级林业主管部门会同有关部门批文" },
          { name: "省级拍卖业主管部门核发许可文件" },
          { name: "承装(修、试)电力设施许可证" },
          { name: "省级出版行政部门批文" },
          { name: "省级音像制品行政管理部门批文" },
          { name: "省级卫生行政部门会同省级药品监督管理部门批文" },
          { name: "县级人民政府多镇企业主管部门批文" },
          { name: "省级人民政府规定的审批部门批文" },
          { name: "县以上主管部门批文" },
          { name: "省级药品监督管理部门指定计划文" },
          { name: "县级人民政府批文" },
          { name: "省级卫生行政部门批文" },
          { name: "县级卫生行政部门会同县级药品监督管理部门批文" },
          { name: "省级交通主管部门登记文" },
          { name: "省级人民政府批文" },
          { name: "县级以上宗教事务管理部门批文" },
          { name: "县级以上药品监督管理部门批文" },
          { name: "是级以上粮食行政管理部门批文" },
        ];
      } else if (this.formInline.name == "其他") {
        this.typeOption = [
          { name: "因私出入境中介经营许可证" },
          { name: "自费出国留学中介服务资格认定书" },
          { name: "职业介绍许可证" },
          { name: "境外就业中介许可证" },
          { name: "基他" },
          { name: "安全合格证书" },
          { name: "产品型式认可证书" },
          { name: "建筑业企业资质证书" },
          { name: "旅行社许可证" },
          { name: "卫生许可证" },
          { name: "消防许可证" },
          { name: "安全生产许可证" },
          { name: "安全合格证书" },
          { name: "军队企业证书" },
          { name: "餐饮服务许可证" },
        ];
      }
    },
    handleChange(e) {
      this.formInline.nameItem = "";
      this.filterType();
    },
  },
};
</script>

<style lang="scss" scoped>
.top_select {
  margin-bottom: 10px;
  text-align: right;
}
.el-row {
  display: flex;
  border: 1px solid #b9b9b9;
  border-bottom: none;
  border-right: none;
}
.el-row:first-child {
  border-top: 1px solid #b9b9b9;
}
.el-row:last-child {
  border-bottom: 1px solid #b9b9b9;
}
.el-col-5 {
  text-align: right;
  background-color: #ecf7f9;
  padding-right: 10px;
  border-right: 1px solid #b9b9b9;
  font-size: 14px;
  height: 34px;
  line-height: 34px;
}
.el-col-19 {
  padding-left: 10px;
  border-right: 1px solid #b9b9b9;
  height: 34px;
  line-height: 34px;
}
</style>

<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      v-model="dialogFormVisible"
      :title="comInfo.name"
      width="800px"
      destroy-on-close
    >
      <el-table
        :data="tableData"
        style="width: 100%"
        border
        v-loading="loading"
      >
        <el-table-column label="报表名称">
          <template #default="scope">
            <span
              style="cursor: pointer;"
              @click="handleEdit(scope.row.name)"
              >{{ scope.row.name }}</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="" label="操作" width="100" align="center">
          <template #default="scope">
            <el-button
              @click="handleEdit(scope.row.name)"
              type="primary"
              size="small"
              plain
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <comBasicTable
      :comId="this.comId"
      :startAccountPeriod="this.yearTime"
      ref="comBasicTable"
    ></comBasicTable>
    <gdjczxxTable
      :comId="this.comId"
      :startAccountPeriod="this.yearTime"
      ref="gdjczxxTable"
    ></gdjczxxTable>
    <zczkxxTable
      :comId="this.comId"
      :startAccountPeriod="this.yearTime"
      ref="zczkxxTable"
    ></zczkxxTable>
    <djxxTable
      :comId="this.comId"
      :startAccountPeriod="this.yearTime"
      ref="djxxTable"
    ></djxxTable>
    <sbxxTable
      :comId="this.comId"
      :startAccountPeriod="this.yearTime"
      ref="sbxxTable"
    ></sbxxTable>
    <tzsbxxTable
      :comId="this.comId"
      :startAccountPeriod="this.yearTime"
      ref="tzsbxxTable"
    ></tzsbxxTable>
    <hgbgxxTable
      :comId="this.comId"
      :startAccountPeriod="this.yearTime"
      ref="hgbgxxTable"
    ></hgbgxxTable>
    <xzxkwjTable
      :comId="this.comId"
      :startAccountPeriod="this.yearTime"
      ref="xzxkwjTable"
    ></xzxkwjTable>
    <wzhwdxxTable
      :comId="this.comId"
      :startAccountPeriod="this.yearTime"
      ref="wzhwdxxTable"
    ></wzhwdxxTable>
    <fzjgqkTable
      :comId="this.comId"
      :startAccountPeriod="this.yearTime"
      ref="fzjgqkTable"
    ></fzjgqkTable>
  </div>
</template>

<script>
import comBasicTable from "../../report/businessAnnualReport/comBasicTable.vue";
import gdjczxxTable from "../../report/businessAnnualReport/gdjczxxTable.vue";
import zczkxxTable from "../../report/businessAnnualReport/zczkxxTable.vue";
import djxxTable from "../../report/businessAnnualReport/djxxTable.vue";
import sbxxTable from "../../report/businessAnnualReport/sbxxTable.vue";
import tzsbxxTable from "../../report/businessAnnualReport/tzsbxxTable.vue";
import hgbgxxTable from "../../report/businessAnnualReport/hgbgxxTable.vue";
import xzxkwjTable from "../../report/businessAnnualReport/xzxkwjTable.vue";
import wzhwdxxTable from "../../report/businessAnnualReport/wzhwdxxTable.vue";
import fzjgqkTable from "../../report/businessAnnualReport/fzjgqkTable.vue";

export default {
  name: "checkQysdYear",
  components: {
    comBasicTable,
    gdjczxxTable,
    zczkxxTable,
    djxxTable,
    sbxxTable,
    tzsbxxTable,
    hgbgxxTable,
    xzxkwjTable,
    wzhwdxxTable,
    fzjgqkTable
  },
  props: {
    yearTime: {
      default: "",
      type: String,
    },
  },
  data() {
    return {
      dialogFormVisible: false,
      comId: 0,
      tableData: [
        {
          name: "企业基本信息",
        },
        {
          name: "股东及出资信息",
        },
        {
          name: "资产状况信息",
        },
        {
          name: "党建信息",
        },
        {
          name: "社保信息",
        },
        {
          name: "海关报关信息",
        },
        {
          name: "特种设备信息",
        },
      ],
      loading: false,
      comInfo: {},
    };
  },

  mounted() {},

  methods: {
    init(item) {
      console.log(item);
      this.comId = item.id;
      this.comInfo = item;
      if (item.companyType == "农村合作社") {
        this.tableData = [
          {
            name: "企业基本信息",
          },
          {
            name: "行政许可文件",
          },
          {
            name: "网站或网店信息",
          },
          {
            name: "分支机构情况",
          },
          // {
          //   name: "股东及出资信息",
          // },
          {
            name: "资产状况信息",
          },
          // {
          //   name: "党建信息",
          // },
          {
            name: "社保信息",
          },
          // {
          //   name: "海关报关信息",
          // },
          {
            name: "特种设备信息",
          },
        ];
      } else {
        this.tableData = [
          {
            name: "企业基本信息",
          },
          {
            name: "股东及出资信息",
          },
          {
            name: "资产状况信息",
          },
          {
            name: "党建信息",
          },
          {
            name: "社保信息",
          },
          {
            name: "网站或网店信息",
          },
          {
            name: "海关报关信息",
          },
          {
            name: "特种设备信息",
          },
        ];
      }
      this.dialogFormVisible = true;
    },
    // 跳转页面
    handleEdit(e) {
      if (e == "企业基本信息") {
        this.$refs.comBasicTable.getParams(this.comInfo.companyType);
      } else if (e == "股东及出资信息") {
        this.$refs.gdjczxxTable.getParams();
      } else if (e == "资产状况信息") {
        this.$refs.zczkxxTable.getParams(this.comInfo.companyType);
      } else if (e == "党建信息") {
        this.$refs.djxxTable.getParams(this.comInfo.district);
      } else if (e == "社保信息") {
        this.$refs.sbxxTable.getParams(this.comInfo);
      } else if (e == "特种设备信息") {
        this.$refs.tzsbxxTable.getParams();
      } else if (e == "海关报关信息") {
        this.$refs.hgbgxxTable.getParams();
      } else if (e == "行政许可文件") {
        this.$refs.xzxkwjTable.getParams();
      } else if (e == "网站或网店信息") {
        this.$refs.wzhwdxxTable.getParams();
      } else if (e == "分支机构情况") {
        this.$refs.fzjgqkTable.getParams();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>

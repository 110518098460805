<template>
  <el-dialog
    :close-on-click-modal="false"
    v-model="dialogFormVisible"
    title="网站或网店信息"
    width="1000px"
    destroy-on-close
  >
    <div class="top_select">
      <el-button @click="add" type="primary" size="small" plain icon="Plus"
        >添加</el-button
      >
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      v-loading="loading"
      :height="contentStyleObj"
    >
      <el-table-column type="index" width="50" label="序号" align="center" />
      <el-table-column prop="type" label="类型" width="100px" />
      <el-table-column prop="name" label="名称" min-width="150px" />
      <el-table-column prop="url" label="网址" min-width="150px" />
      <el-table-column prop="name" label="操作" width="150" align="center">
        <template #default="scope">
          <el-button
            @click="handleAdd(scope.row)"
            type="primary"
            size="small"
            plain
            >修改</el-button
          >
          <el-button @click="del(scope.row)" type="danger" size="small" plain
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <template #footer>
      <div class="dialog-footer">
        <el-button
          @click="dialogFormVisible = false"
          size="small"
          type="primary"
          >确定</el-button
        >
      </div>
    </template>
  </el-dialog>
  <el-dialog
    :close-on-click-modal="false"
    v-model="dialogFormVisibleAdd"
    title="网站或网店信息"
    width="800px"
    destroy-on-close
  >
    <el-row>
      <el-col :span="5">
        网站或网店类型
      </el-col>
      <el-col :span="19">
        <el-select
          size="small"
          v-model="formInline.type"
          style="margin: 0;width: 300px;"
        >
          <el-option label="网站" value="网站"></el-option>
          <el-option label="网店" value="网店"></el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="5">
        网站或网店名称
      </el-col>
      <el-col :span="19">
        <el-input
          size="small"
          v-model="formInline.name"
          style="width: 300px;"
        ></el-input>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="5">
        网站或网店网址
      </el-col>
      <el-col :span="19">
        <el-input
          size="small"
          v-model="formInline.url"
          style="width: 300px;"
        ></el-input>
      </el-col>
    </el-row>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="dialogFormVisibleAdd = false" size="small"
          >取消</el-button
        >
        <el-button type="primary" @click="save" size="small">
          保存
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { getCompanyInfo, updateGsnb, deleteGsnb } from "@/api/gsnb";
export default {
  name: "wzhwdxxTable",
  props: {
    startAccountPeriod: {
      default: "",
      type: String,
    },
    comId: {
      default: 0,
      type: Number,
    },
  },
  data() {
    return {
      dialogFormVisible: false,
      dialogFormVisibleAdd: false,
      tableData: [],
      formInline: {},
      contentStyleObj: {},
      loading: false,
    };
  },

  mounted() {
    this.contentStyleObj = this.$getHeight(300);
  },

  methods: {
    getParams() {
      this.dialogFormVisible = true;
      this.getList();
    },
    getList() {
      this.loading = true;
      getCompanyInfo({
        comId: this.comId,
        year: this.startAccountPeriod,
        tableName: "gsnb_website",
      }).then((res) => {
        this.loading = false;
        if (res.data.msg == "success") {
          this.tableData = res.data.data.data;
        }
      });
    },
    handleAdd(row) {
      this.formInline = Object.assign({}, row);
      this.dialogFormVisibleAdd = true;
    },
    add() {
      this.formInline = {};
      this.dialogFormVisibleAdd = true;
    },
    save() {
      if (
        !this.formInline.type ||
        !this.formInline.name ||
        !this.formInline.url
      ) {
        this.$qzfMessage("请填写完整信息", 1);
        return;
      }
      this.formInline.comId = this.comId;
      this.formInline.year = this.startAccountPeriod;
      let param = {
        tableName: "gsnb_website",
        query: this.formInline,
      };
      updateGsnb(param).then((res) => {
        if (res.data.msg == "success") {
          this.$qzfMessage("保存成功");
          this.dialogFormVisibleAdd = false;
          this.getList();
        }
      });
    },
    del(row) {
      this.$confirm("确认删除该条信息吗？", "提示", {
        confirmButtonText: "确定",
        cancleButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteGsnb({ ids: [row.id], tableName: "gsnb_website" }).then(
            (res) => {
              if (res.data.msg == "success") {
                this.$qzfMessage("成功删除");
                this.getList();
              }
            }
          );
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.top_select {
  margin-bottom: 10px;
  text-align: right;
}
.el-row {
  display: flex;
  border: 1px solid #b9b9b9;
  border-bottom: none;
  border-right: none;
}
.el-row:first-child {
  border-top: 1px solid #b9b9b9;
}
.el-row:last-child {
  border-bottom: 1px solid #b9b9b9;
}
.el-col-5 {
  text-align: right;
  background-color: #ecf7f9;
  padding-right: 10px;
  border-right: 1px solid #b9b9b9;
  font-size: 14px;
  height: 34px;
  line-height: 34px;
}
.el-col-19 {
  padding-left: 10px;
  border-right: 1px solid #b9b9b9;
  height: 34px;
  line-height: 34px;
}
</style>

<template>
  <el-dialog
    :close-on-click-modal="false"
    v-model="dialogFormVisible"
    title="股东及出资信息"
    width="90%"
    destroy-on-close
  >
    <div class="top_select">
      <el-button @click="add" type="primary" size="small" plain icon="Plus"
        >添加</el-button
      >
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      v-loading="loading"
      :height="contentStyleObj"
    >
      <el-table-column type="index" width="50" label="序号" />
      <el-table-column prop="name" label="股东" width="100px" />
      <el-table-column
        prop="subscribeCapital"
        label="认缴出资额(万元)"
        width="120px"
      />
      <el-table-column
        prop="subscribeTime"
        label="认缴出资时间"
        width="120px"
      />
      <el-table-column prop="subscribeWays" label="认缴出资方式" />
      <el-table-column
        prop="contributedCapital"
        label="实缴出资额(万元)"
        width="120px"
      />
      <el-table-column
        prop="contributedTime"
        label="实缴出资时间"
        width="120px"
      />
      <el-table-column prop="contributedWays" label="实缴出资方式" />
      <el-table-column prop="name" label="操作" width="150" align="center">
        <template #default="scope">
          <el-button
            @click="handleAdd(scope.row)"
            type="primary"
            size="small"
            plain
            >修改</el-button
          >
          <el-button @click="del(scope.row)" type="danger" size="small" plain
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <template #footer>
      <div class="dialog-footer">
        <el-button
          @click="dialogFormVisible = false"
          size="small"
          type="primary"
          >确定</el-button
        >
      </div>
    </template>
  </el-dialog>
  <el-dialog
    :close-on-click-modal="false"
    v-model="dialogFormVisibleAdd"
    title="股东信息"
    width="1000px"
    destroy-on-close
  >
    <div>
      <el-input size="small" style="width: 480px;" v-model="formInline.name">
        <template #prepend>股东：</template>
      </el-input>
    </div>
    <div style="margin: 20px 0;">
      <el-row>
        <el-col :span="5">
          <div style="color: red;">
            认缴出资额（万元）
          </div>
        </el-col>
        <el-col :span="7">
          <div>
            <el-input
              v-model="formInline.subscribeCapital"
              clearable
              size="small"
              style="width: 220px;"
            />
          </div>
        </el-col>
        <el-col :span="5">
          <div>
            认缴出资时间
          </div>
        </el-col>
        <el-col :span="7">
          <div>
            <el-date-picker
              v-model="formInline.subscribeTime"
              type="date"
              clearable
              size="small"
              value-format="YYYY-MM-DD"
            />
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="5">
          <div style="text-align: center;">
            认缴出资币种
          </div>
        </el-col>
        <el-col :span="19">
          <div>
            <el-select
              size="small"
              v-model="formInline.subscribeCurrency"
              clearable
              style="width: 220px;margin: 0;"
            >
              <el-option label="人民币" :value="0"></el-option>
              <el-option label="美元" :value="1"></el-option>
              <el-option label="日元" :value="2"></el-option>
              <el-option label="欧元" :value="3"></el-option>
              <el-option label="港元" :value="4"></el-option>
              <el-option label="英镑" :value="5"></el-option>
              <el-option label="德国马克" :value="6"></el-option>
              <el-option label="加拿大元" :value="7"></el-option>
              <el-option label="法国法郎" :value="8"></el-option>
              <el-option label="荷兰" :value="9"></el-option>
              <el-option label="瑞士法郎" :value="10"></el-option>
              <el-option label="新加坡元" :value="11"></el-option>
              <el-option label="澳大利亚元" :value="12"></el-option>
              <el-option label="丹麦克郎" :value="13"></el-option>
            </el-select>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="5">
          <div>
            认缴出资方式
          </div>
        </el-col>
        <el-col :span="19">
          <div style="margin-top: 5px;">
            <el-checkbox-group v-model="formInline.subscribeWays" size="small">
              <el-checkbox label="货币" />
              <el-checkbox label="实物" />
              <el-checkbox label="知识产权" />
              <el-checkbox label="债权" />
              <el-checkbox label="土地使用权" />
              <el-checkbox label="股权" />
              <el-checkbox label="其他" />
            </el-checkbox-group>
          </div>
        </el-col>
      </el-row>
    </div>
    <div>
      <el-row>
        <el-col :span="5">
          <div>
            实缴出资额（万元）
          </div>
        </el-col>
        <el-col :span="7">
          <div>
            <el-input
              v-model="formInline.contributedCapital"
              clearable
              size="small"
              style="width: 220px;"
            />
          </div>
        </el-col>
        <el-col :span="5">
          <div>
            实缴出资时间
          </div>
        </el-col>
        <el-col :span="7">
          <div>
            <el-date-picker
              v-model="formInline.contributedTime"
              type="date"
              clearable
              size="small"
              value-format="YYYY-MM-DD"
            />
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="5">
          <div style="text-align: center;">
            实缴出资币种
          </div>
        </el-col>
        <el-col :span="19">
          <div>
            <el-select
              size="small"
              v-model="formInline.contributedCurrency"
              clearable
              style="width: 220px;margin: 0;"
            >
              <el-option label="人民币" :value="0"></el-option>
              <el-option label="美元" :value="1"></el-option>
              <el-option label="日元" :value="2"></el-option>
              <el-option label="欧元" :value="3"></el-option>
              <el-option label="港元" :value="4"></el-option>
              <el-option label="英镑" :value="5"></el-option>
              <el-option label="德国马克" :value="6"></el-option>
              <el-option label="加拿大元" :value="7"></el-option>
              <el-option label="法国法郎" :value="8"></el-option>
              <el-option label="荷兰" :value="9"></el-option>
              <el-option label="瑞士法郎" :value="10"></el-option>
              <el-option label="新加坡元" :value="11"></el-option>
              <el-option label="澳大利亚元" :value="12"></el-option>
              <el-option label="丹麦克郎" :value="13"></el-option>
            </el-select>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="5">
          <div>
            实缴出资方式
          </div>
        </el-col>
        <el-col :span="19">
          <div style="margin-top: 5px;">
            <el-checkbox-group
              v-model="formInline.contributedWays"
              size="small"
            >
              <el-checkbox label="货币" />
              <el-checkbox label="实物" />
              <el-checkbox label="知识产权" />
              <el-checkbox label="债权" />
              <el-checkbox label="土地使用权" />
              <el-checkbox label="股权" />
              <el-checkbox label="其他" />
            </el-checkbox-group>
          </div>
        </el-col>
      </el-row>
    </div>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="dialogFormVisibleAdd = false" size="small"
          >取消</el-button
        >
        <el-button type="primary" @click="save" size="small">
          保存
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { getCompanyInfo, updateGsnb, deleteGsnb } from "@/api/gsnb";
export default {
  name: "EtaxWebV3GdjczxxTable",
  props: {
    startAccountPeriod: {
      default: "",
      type: String,
    },
    comId: {
      default: 0,
      type: Number,
    },
  },
  data() {
    return {
      dialogFormVisible: false,
      dialogFormVisibleAdd: false,
      tableData: [],
      formInline: {},
      contentStyleObj: {},
      loading: false,
    };
  },

  mounted() {
    this.contentStyleObj = this.$getHeight(300);
  },

  methods: {
    getParams() {
      this.dialogFormVisible = true;
      this.getList();
    },
    getList() {
      this.loading = true;
      getCompanyInfo({
        comId: this.comId,
        year: this.startAccountPeriod,
        tableName: "gsnb_shareholder",
      }).then((res) => {
        this.loading = false;
        if (res.data.msg == "success") {
          this.tableData = res.data.data.data;
        }
      });
    },
    handleAdd(row) {
      this.formInline = Object.assign({}, row);
      this.dialogFormVisibleAdd = true;
    },
    add() {
      this.formInline = {};
      this.dialogFormVisibleAdd = true;
    },
    save() {
      this.formInline.subscribeCapital = this.formInline.subscribeCapital
        ? Number(this.formInline.subscribeCapital)
        : 0;
      this.formInline.contributedCapital = this.formInline.contributedCapital
        ? Number(this.formInline.contributedCapital)
        : 0;
      this.formInline.comId = this.comId;
      this.formInline.year = this.startAccountPeriod;
      let param = {
        tableName: "gsnb_shareholder",
        query: this.formInline,
      };
      updateGsnb(param).then((res) => {
        if (res.data.msg == "success") {
          this.$qzfMessage("保存成功");
          this.dialogFormVisibleAdd = false;
          this.getList();
        }
      });
    },
    del(row) {
      this.$confirm("确认删除该条信息吗？", "提示", {
        confirmButtonText: "确定",
        cancleButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteGsnb({ ids: [row.id],tableName: "gsnb_shareholder" }).then((res) => {
            if (res.data.msg == "success") {
              this.$qzfMessage("成功删除");
              this.getList();
            }
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.top_select {
  margin-bottom: 10px;
  text-align: right;
}
.el-row {
  display: flex;
  border: 1px solid #b9b9b9;
  border-bottom: none;
  border-right: none;
}
.el-row:first-child {
  border-top: 1px solid #b9b9b9;
}
.el-row:last-child {
  border-bottom: 1px solid #b9b9b9;
}
.el-col-5 {
  text-align: right;
  background-color: #ecf7f9;
  padding-right: 10px;
  border-right: 1px solid #b9b9b9;
  font-size: 14px;
  height: 34px;
  line-height: 34px;
}
.el-col-7,
.el-col-19 {
  padding-left: 10px;
  border-right: 1px solid #b9b9b9;
  height: 34px;
  line-height: 34px;
}
</style>

<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      v-model="dialogFormVisible"
      title="特种设备信息"
      width="1000px"
      destroy-on-close
      center
    >
      <table class="content" cellspacing="0" width="100%" v-loading="loading">
        <tr>
          <td class="right" style="width:40%;">
            办理使用登记特种设备总台数
          </td>
          <td style="width:60%;">
            <el-input
              v-model.number="form.registerFacilityNum"
              size="small"
              style="width:70%"
            >
            </el-input
            >台（套数）
          </td>
        </tr>
        <tr>
          <td class="right" style="width:40%;">
            检验有效期内特种设备总台数
          </td>
          <td style="width:60%;">
            <el-input
              v-model.number="form.validFacilityNum"
              size="small"
              style="width:70%"
            >
            </el-input
            >台（套数）
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <p>说明：</p>
            <div class="left_k">
              1、办理使用登记特种设备总台（套）数:特种设备使用单位办理使用登记的特种设备数量，包括锅炉、压力容器（不含气瓶）、电梯、起重机械、大型游乐设施、客运索道和场（厂）内专用机动车辆，由实际办理使用登记手续的单位填报。没有上述使用登记设备的填"0"。
            </div>
            <div class="left_k">
              2、检验有效期内特种设备总台（套）数:特种设备使用单位已办理使用登记并在检验合格有效期内的特种设备数量，包括锅炉、压力容器（不含气瓶）、电梯、起重机械、大型游乐设施、客运索道和场（厂）内专用机动车辆。
            </div>
          </td>
        </tr>
      </table>

      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogFormVisible = false" size="small"
            >关闭</el-button
          >
          <el-button type="primary" @click="save" size="small">
            保存
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { getCompanyInfo, updateGsnb } from "@/api/gsnb";

export default {
  name: "tzsbxxTable",
  props: {
    startAccountPeriod: {
      default: "",
      type: String,
    },
    comId: {
      default: 0,
      type: Number,
    },
  },
  data() {
    return {
      dialogFormVisible: false,
      form: {},
      loading:false
    };
  },

  mounted() {},

  methods: {
    getParams() {
      this.dialogFormVisible = true;
      this.getList();
    },
    getList() {
      this.loading = true
      getCompanyInfo({
        comId: this.comId,
        year: this.startAccountPeriod,
        tableName: "gsnb_specialEquipment",
      }).then((res) => {
        this.loading = false
        if (res.data.msg == "success") {
          this.form = res.data.data.data;
        }
      });
    },
    save() {
      let param = {
        tableName: "gsnb_specialEquipment",
        query: this.form,
      };
      updateGsnb(param).then((res) => {
        if (res.data.msg == "success") {
          this.$qzfMessage("保存成功");
          this.getList();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  border: 1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  tr {
    padding: 0;
    margin: 0;
  }
  td {
    border-right: 1px solid #c2c2c2;
    border-bottom: 1px solid #c2c2c2;
    height: 35px;
    line-height: 35px;
    padding: 0 6px;
    color: #333;
    font-size: 13px;
  }
}
.right {
  text-align: right;
  background-color: #ecf7f9;
}
.center {
  text-align: center;
  background-color: #ecf7f9;
}
.left_k {
  text-indent: 2em;
  line-height: 20px;
  margin-bottom: 10px;
}
</style>

<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      v-model="dialogFormVisible"
      title="党建信息"
      width="900px"
      destroy-on-close
      center
    >
      <table class="content" cellspacing="0" width="100%" v-loading="loading" v-if="dirtict!=='hunan'">
        <tr>
          <td class="center" style="width: 10%;" rowspan="5">党建信息</td>
          <td class="right" style="width:35%;">是否有党建信息</td>
          <td style="width:45%;">
            <el-radio-group v-model="form.haveParty" size="small">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </td>
          <td style="width:10%;"></td>
        </tr>
        <tr>
          <td class="right">中共党员（包括预备党员）人数</td>
          <td>
            <el-input v-model.number="form.partyMemberNum" size="small">
              <template #append>人</template>
            </el-input>
          </td>
          <td>
            不公示
          </td>
        </tr>
        <tr>
          <td class="right">党组织建制</td>
          <td>
            <el-radio-group v-model="form.organizational" size="small">
              <el-radio :label="1">党委</el-radio>
              <el-radio :label="2">党总支</el-radio>
              <el-radio :label="3">党支部</el-radio>
              <el-radio :label="4">未成立党组织</el-radio>
            </el-radio-group>
          </td>
          <td>
            不公示
          </td>
        </tr>
        <tr>
          <td class="right">经营者是否为党员</td>
          <td>
            <el-radio-group v-model="form.isParty" size="small">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </td>
          <td>
            不公示
          </td>
        </tr>
        <tr>
          <td class="right">经营者是否为党组织书记</td>
          <td>
            <el-radio-group v-model="form.isSecretary" size="small">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </td>
          <td>
            不公示
          </td>
        </tr>
        <tr>
          <td class="center" rowspan="4">团建信息</td>
          <td class="right">团员人数</td>
          <td>
            <el-input v-model.number="form.leagueMenberNum" size="small">
              <template #append>人</template>
            </el-input>
          </td>
          <td>
            不公示
          </td>
        </tr>
        <tr>
          <td class="right">团组织建制</td>
          <td>
            <el-radio-group v-model="form.leagueOrganizational" size="small">
              <el-radio :label="1">团委</el-radio>
              <el-radio :label="2">团总支</el-radio>
              <el-radio :label="3">团支部</el-radio>
              <el-radio :label="4">未成立团组织</el-radio>
            </el-radio-group>
          </td>
          <td>
            不公示
          </td>
        </tr>
        <tr>
          <td class="right">经营者是否为团员</td>
          <td>
            <el-radio-group v-model="form.isLeague" size="small">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </td>
          <td>
            不公示
          </td>
        </tr>
        <tr>
          <td class="right">经营者是否为团组织书记</td>
          <td>
            <el-radio-group v-model="form.isLeagueSecretary" size="small">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </td>
          <td>
            不公示
          </td>
        </tr>
      </table>
<!-- 湖南党建 -->
      <table class="content" cellspacing="0" width="100%" v-loading="loading" v-if="dirtict=='hunan'">
          <tr>
            <td class="right" style="width: 20%;" rowspan="3">企业基本情况</td>
            <td class="right" style="width:15%;">
              <span style="color: red;">*</span>
              是否建立党组织
            </td>
            <td style="width:55%;" colspan="3" >
              <el-radio-group v-model="form.party" size="small" @change="partyChange(form.party)">
                <el-radio :label="'1'">建立党组织</el-radio>
                <el-radio :label="'0'">未建立党组织</el-radio>
              </el-radio-group>
            </td>
          </tr>
          <tr>
            <td class="right">
              <span style="color: red;">*</span>
              法定代表人是否党员
            </td>
            <td colspan="3" >
              <el-radio-group  v-model="form.isParty"  size="small">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </td>
          </tr>
          <tr>
            <td class="right">
              <span style="color: red;">*</span>
              法定代表人是否党组织书记
            </td>
            <td colspan="3" >
              <el-radio-group v-model="form.isSecretary" size="small">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </td>
          </tr>
          <tr>
            <td class="right" style="width: 10%;" rowspan="8" v-if="form.party==1">党组织组建情况</td>
            <td class="right" style="width: 10%;" rowspan="4" v-else>党组织组建情况</td>
            <td class="center" colspan="4" style="width:35%;" v-if="form.party==1">以下栏目请建立党组织的企业编写</td>
            <td class="center" colspan="4" style="width:35%;" v-else>以下栏目请未建立党组织的企业编写</td>
          </tr>
          <tr v-if="form.party==1">
            <td class="right"  style="width: 5%;">
              <span style="color: red;">*</span>
              党组织名称
            </td>
            <td>
              <el-input v-model="form.partyName" placeholder="请输入党组织名称" size="small">
              </el-input>
            </td>
            <td class="right"  style="width: 10%;">
              <span style="color: red;">*</span>
              党组织代码
            </td>
            <td>
              <el-input v-model="form.partyCode" placeholder="请输入党组织代码" size="small">
              </el-input>
            </td>
          </tr>
          <tr v-if="form.party==1">
            <td class="right"  style="width: 5%;">
              <span style="color: red;">*</span>
              党组织主管/隶属单位统一社会信用代码
            </td>
            <td>
              <el-input v-model="form.partyTaxNo" placeholder="请输入党组织主管/隶属单位统一社会信用代码" size="small">
              </el-input>
            </td>
            <td class="right"  style="width: 10%;">
              <span style="color: red;">*</span>
              党组织建制负责人姓名
            </td>
            <td>
              <el-input v-model="form.partyManagersName" placeholder="请输入党组织建制负责人姓名" size="small">
              </el-input>
            </td>
          </tr>
          <tr v-if="form.party==1">
            <td class="right"  style="width: 5%;">
              <span style="color: red;">*</span>
              电子邮箱
            </td>
            <td>
              <el-input v-model="form.partyEmail" placeholder="请输入电子邮箱" size="small">
              </el-input>
            </td>
            <td class="right"  style="width: 10%;">
              <span style="color: red;">*</span>
              办公电话
            </td>
            <td>
              <el-input v-model="form.partyPhone" placeholder="请输入办公电话" size="small">
              </el-input>
            </td>
          </tr>
          <tr v-if="form.party==1">
            <td class="right"  style="width: 5%;">
              <span style="color: red;">*</span>
              手机
            </td>
            <td>
              <el-input v-model="form.partyMobile" placeholder="请输入手机" size="small">
              </el-input>
            </td>
            <td class="right"  style="width: 10%;"></td>
            <td>
            </td>
          </tr>
          <tr v-if="form.party==1">
            <td class="right">
              <span style="color: red;">*</span>
              党组织主管/隶属单位
            </td>
            <td colspan="3" >
              <el-radio-group v-model="form.partySubjection" size="small">
                <el-radio :label="'1'">组织部</el-radio>
                <el-radio :label="'2'">工商联/非公党委</el-radio>
                <el-radio :label="'3'">工商局/个私协会</el-radio>
                <el-radio :label="'4'">其它</el-radio>
              </el-radio-group>
            </td>
          </tr>
          <tr v-if="form.party==1">
            <td class="right">
              <span style="color: red;">*</span>
              党组织建制情况
            </td>
            <td colspan="3" >
              <el-radio-group v-model="form.organizational" size="small">
                <el-radio :label="1">党委</el-radio>
                <el-radio :label="2">党总支</el-radio>
                <el-radio :label="3">党支部</el-radio>
                <el-radio :label="4">未成立</el-radio>
              </el-radio-group>
            </td>
          </tr>
          <tr v-if="form.party==1">
            <td class="right">
              <span style="color: red;">*</span>
              党组织组建方式
            </td>
            <td colspan="3" >
              <el-radio-group v-model="form.partyConstructionMode"  size="small">
                <el-radio :label="'1'">单独组建</el-radio>
                <el-radio :label="'2'">联合组建</el-radio>
                <el-radio :label="'3'">其他</el-radio>
              </el-radio-group>
            </td>
          </tr>
          <tr v-if="form.party==1">
            <td class="right">
              <span style="color: red;">*</span>
              党员人数（含预备党员）
            </td>
            <td>
              <el-input v-model.number="form.partyMemberNum" placeholder="请输入党员人数（含预备党员）" size="small">
              </el-input>
            </td>
            <td class="right">
              <span style="color: red;">*</span>
              本年度发展新党员
            </td>
            <td colspan="2">
              <el-input v-model="form.partyNewMember" placeholder="请输入本年度发展新党员" size="small">
              </el-input>
            </td>
          </tr>
          <tr v-if="form.party==1">
            <td class="right">
              <span style="color: red;">*</span>
              党组织组建时间
            </td>
            <td>
              <el-date-picker  v-model="form.partyBuildTime" value-format="YYYY-MM-DD" type="date" placeholder="请选择日期" size="small">
              </el-date-picker>
            </td>
            <td colspan="3">
            </td>
          </tr>
          <tr v-if="form.party==0">
            <td class="right"  style="width: 5%;">
              <span style="color: red;">*</span>
              党员人数（含预备党员）
            </td>
            <td>
              <el-input v-model.number="form.partyMemberNum" placeholder="请输入党员人数（含预备党员）" size="small">
              </el-input>
            </td>
            <td class="right"  style="width: 10%;">
              <span style="color: red;">*</span>
              党组织拟组建时间
            </td>
            <td>
              <el-date-picker  v-model="form.partyUpcomingTime" value-format="YYYY-MM-DD" type="date" placeholder="请选择日期" size="small">
              </el-date-picker>
            </td>
          </tr>
          <tr v-if="form.party==0">
            <td class="right">
              <span style="color: red;">*</span>
              党组织拟建制情况
            </td>
            <td colspan="3" >
              <el-radio-group v-model="form.partyUpcomingCondition" size="small">
                <el-radio :label="'1'">党委</el-radio>
                <el-radio :label="'2'">党总支</el-radio>
                <el-radio :label="'3'">党支部</el-radio>
                <el-radio :label="'4'">未成立</el-radio>
              </el-radio-group>
            </td>
          </tr>
          <tr v-if="form.party==0">
            <td class="right">
              <span style="color: red;">*</span>
              党组织拟组建方式
            </td>
            <td colspan="3" >
              <el-radio-group v-model="form.partyUpcomingOrganizeWay" size="small">
                <el-radio :label="'1'">单独组建</el-radio>
                <el-radio :label="'2'">联合组建</el-radio>
                <el-radio :label="'3'">其他</el-radio>
              </el-radio-group>
            </td>
          </tr>

          <tr>
            <td class="right" style="width: 20%;" rowspan="9">团组织组建情况</td>
            <td class="right" style="width:25%;">
              <span style="color: red;">*</span>
              是否建立团组织
            </td>
            <td style="width:45%;" colspan="3" >
              <el-radio-group v-model="form.league" size="small" @change="leagueChange(form.league)">
                <el-radio :label="'1'">建立团组织</el-radio>
                <el-radio :label="'0'">未建立团组织</el-radio>
              </el-radio-group>
            </td>
          </tr>
          <tr>
            <td class="center" colspan="4" style="width:35%;" v-if="form.league==1">以下栏目请建立团组织的企业编写</td>
            <td class="center" colspan="4" style="width:35%;" v-else>以下栏目请未建立团组织的企业编写</td>
          </tr>
          <tr v-if="form.league==1">
            <td class="right"  style="width: 5%;">
              <span style="color: red;">*</span>
              团组织负责人姓名
            </td>
            <td>
              <el-input v-model="form.leagueManagersName" placeholder="请输入团组织负责人姓名" size="small">
              </el-input>
            </td>
            <td class="right"  style="width: 10%;">
              <span style="color: red;">*</span>
              性别
            </td>
            <td>
              <el-radio-group v-model="form.leagueManagersSex" size="small">
                <el-radio :label="'1'">男</el-radio>
                <el-radio :label="'0'">女</el-radio>
              </el-radio-group>
            </td>
          </tr>
          <tr v-if="form.league==1">
            <td class="right"  style="width: 5%;">
              <span style="color: red;">*</span>
              办公电话
            </td>
            <td>
              <el-input v-model="form.leaguePartyPhone" placeholder="请输入办公电话" size="small">
              </el-input>
            </td>
            <td class="right"  style="width: 10%;">
              <span style="color: red;">*</span>
              手机
            </td>
            <td>
              <el-input v-model="form.leaguePartyMobile" placeholder="请输入手机" size="small">
              </el-input>
            </td>
          </tr>
          <tr v-if="form.league==1">
            <td class="right">
              <span style="color: red;">*</span>
              团组织主管/隶属单位
            </td>
            <td colspan="3" >
              <el-radio-group v-model="form.leaguePartySubjection" size="small">
                <el-radio :label="'1'">工商局/非公团工委</el-radio>
                <el-radio :label="'2'">市，县，街道等团委</el-radio>
                <el-radio :label="'3'">其他</el-radio>
              </el-radio-group>
            </td>
          </tr>
          <tr v-if="form.league==1">
            <td class="right">
              <span style="color: red;">*</span>
              团组织建制情况
            </td>
            <td colspan="3" >
              <el-radio-group v-model="form.leagueOrganizational"  size="small">
                <el-radio :label="1">团委</el-radio>
                <el-radio :label="2">团总支</el-radio>
                <el-radio :label="3">团支部</el-radio>
              </el-radio-group>
            </td>
          </tr>
          <tr v-if="form.league==1">
            <td class="right">
              <span style="color: red;">*</span>
              团组织组建方式
            </td>
            <td colspan="3" >
              <el-radio-group v-model="form.leagueConstructionMode"  size="small">
                <el-radio :label="'1'">单独组建</el-radio>
                <el-radio :label="'2'">联合组建</el-radio>
                <el-radio :label="'3'">其他</el-radio>
              </el-radio-group>
            </td>
          </tr>
          <tr v-if="form.league==1">
            <td class="right">
              <span style="color: red;">*</span>
              团员人数（含预备团员）
            </td>
            <td>
              <el-input v-model.number="form.leagueMenberNum" placeholder="请输入团员人数（含预备团员）" size="small">
              </el-input>
            </td>
            <td class="right">
              <span style="color: red;">*</span>
              35岁以下青年人数
            </td>
            <td colspan="2">
              <el-input  v-model="form.leagueStripling" placeholder="请输入35岁以下青年人数" size="small">
              </el-input>
            </td>
          </tr>
          <tr v-if="form.league==1">
            <td class="right">
              <span style="color: red;">*</span>
              团组织组建时间
            </td>
            <td>
              <el-date-picker  v-model="form.leagueBuildTime" value-format="YYYY-MM-DD" type="date" placeholder="请选择日期" size="small">
              </el-date-picker>
            </td>
            <td colspan="3">
            </td>
          </tr>

          <tr v-if="form.league==0">
            <td class="right">
              <span style="color: red;">*</span>
              团员人数（含预备团员）
            </td>
            <td>
              <el-input v-model.number="form.leagueMenberNum" placeholder="请输入团员人数（含预备团员）"  size="small">
              </el-input>
            </td>
            <td class="right">
              <span style="color: red;">*</span>
              35岁以下青年人数
            </td>
            <td colspan="2">
              <el-input  v-model="form.leagueStripling" placeholder="请输入35岁以下青年人数" size="small">
              </el-input>
            </td>
          </tr>
          <tr v-if="form.league==0">
            <td class="right">
              <span style="color: red;">*</span>
              团组织拟建制情况
            </td>
            <td colspan="3" >
              <el-radio-group  v-model="form.leagueUpcomingCondition" size="small">
                <el-radio :label="'1'">团委</el-radio>
                <el-radio :label="'2'">团总支</el-radio>
                <el-radio :label="'3'">团支部</el-radio>
              </el-radio-group>
            </td>
          </tr>
          <tr v-if="form.league==0">
            <td class="right">
              <span style="color: red;">*</span>
              团组织拟组建方式
            </td>
            <td colspan="3" >
              <el-radio-group  v-model="form.leagueOrganizeWay" size="small">
                <el-radio :label="'1'">单独组建</el-radio>
                <el-radio :label="'2'">联合组建</el-radio>
                <el-radio :label="'3'">其他</el-radio>
              </el-radio-group>
            </td>
          </tr>
      </table>

      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogFormVisible = false" size="small"
            >关闭</el-button
          >
          <el-button type="primary" @click="save" size="small">
            保存
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { getCompanyInfo, updateGsnb } from "@/api/gsnb";

export default {
  name: "djxxTable",
  props: {
    startAccountPeriod: {
      default: "",
      type: String,
    },
    comId: {
      default: 0,
      type: Number,
    },
  },
  data() {
    return {
      dialogFormVisible: false,
      form: {
        party:1,
        league:1
      },
      loading: false,
      dirtict: "",//地区是否是湖南
    };
  },

  mounted() {},

  methods: {
    getParams(item) {
      this.dirtict = item
      this.dialogFormVisible = true;
      this.getList();
    },
    getList() {
      this.loading = true;
      getCompanyInfo({
        comId: this.comId,
        year: this.startAccountPeriod,
        tableName: "gsnb_party",
      }).then((res) => {
        this.loading = false;
        this.form.leagueStripling='0'
        this.form.leagueMenberNum='0'
        this.form.partyMemberNum='0'
        if(!this.form.party){
          this.form.party = '1'
        }else if(!this.form.league){
          this.form.league = '1'
        }
        if (res.data.msg == "success") {
          this.form = res.data.data.data;
          if(this.form.leagueManagersSex=='男'){
            this.form.leagueManagersSex='1'
          }else if(this.form.leagueManagersSex=='女'){
            this.form.leagueManagersSex='0'
          }
        }
      });
    },
    // 点击是否建立党组织清空一些字段
    partyChange(item){
      this.form.partyMemberNum='0'
      if(item==0){
        this.form.partyName=''
        this.form.partyCode=''
        this.form.partyTaxNo=''
        this.form.partyManagersName=''
        this.form.partyEmail=''
        this.form.partyPhone=''
        this.form.partyMobile=''
        this.form.partySubjection=''
        this.form.organizational=null
        this.form.partyConstructionMode=''
        this.form.partyNewMember=''
        this.form.partyBuildTime=''
      }else{
        this.form.partyUpcomingTime=''
        this.form.partyUpcomingCondition=''
        this.form.partyUpcomingOrganizeWay=null
      }
    },
    leagueChange(item){
      this.form.leagueStripling='0'
      this.form.leagueMenberNum='0'
      if(item=='0'){
        this.form.leagueManagersName=''
        this.form.leagueManagersSex=''
        this.form.leaguePartyPhone=''
        this.form.partyManagersName=''
        this.form.leaguePartyMobile=''
        this.form.leaguePartySubjection=''
        this.form.leagueOrganizational=null
        this.form.leagueConstructionMode=''
        this.form.leagueBuildTime=''
      }else{
        this.form.leagueUpcomingCondition=''
        this.form.leagueOrganizeWay=''
      }
    },
    save() {
      if(this.form.leagueManagersSex=='1'){
        this.form.leagueManagersSex='男'
      }else if(this.form.leagueManagersSex=='0'){
        this.form.leagueManagersSex='女'
      }
      // 校验是否必填
      if(this.dirtict=='hunan'){
        // 党组织
        if(!this.form.party){
          this.$qzfMessage('请选择是否建立党组织',2)
          return
        }else{
          if(this.form.party==1){
            if(this.form.isParty ==null){
              this.$qzfMessage('请选择法定代表人是否为党员',2)
              return
            }else if(this.form.isSecretary==null){
              this.$qzfMessage('请选择法定代表人是否为党组织书记',2)
              return
            }else if(!this.form.partyName){
              this.$qzfMessage('请输入党组织名称',2)
              return
            }else if(!this.form.partyCode){
              this.$qzfMessage('请输入党组织代码',2)
              return
            }else if(!this.form.partyTaxNo){
              this.$qzfMessage('请输入党组织主管/隶属单位统一社会代码',2)
              return
            }else if(!this.form.partyManagersName){
              this.$qzfMessage('请输入党组织建制负责人姓名',2)
              return
            }else if(!this.form.partyEmail){
              this.$qzfMessage('请输入电子邮箱',2)
              return
            }else if(!this.form.partyPhone){
              this.$qzfMessage('请输入办公电话',2)
              return
            }else if(!this.form.partyMobile){
              this.$qzfMessage('请输入手机',2)
              return
            }else if(!this.form.partySubjection){
              this.$qzfMessage('请选择党组织主管/隶属单位',2)
              return
            }else if(!this.form.organizational){
              this.$qzfMessage('请选择党组织建制情况',2)
              return
            }else if(!this.form.partyConstructionMode){
              this.$qzfMessage('请选择党组织组建方式',2)
              return
            }else if(!this.form.partyMemberNum){
              this.$qzfMessage('请输入党员人数（含预备党员）',2)
              return
            }else if(!this.form.partyNewMember){
              this.$qzfMessage('请输入本年度发展新党员',2)
              return
            }else if(!this.form.partyBuildTime){
              this.$qzfMessage('请选择党组织组建时间',2)
              return
            }
          }else{
            if(!this.form.partyMemberNum){
              this.$qzfMessage('请输入党员人数（含预备党员）',2)
              return
            }else if(!this.form.partyUpcomingTime){
              this.$qzfMessage('请选择党组织拟组建时间',2)
              return
            }else if(!this.form.partyUpcomingCondition){
              this.$qzfMessage('请选择党组织拟建制情况',2)
              return
            }else if(!this.form.partyUpcomingOrganizeWay){
              this.$qzfMessage('请选择党组织拟组建方式',2)
              return
            }
          }
          // 团组织
        if(!this.form.league){
          this.$qzfMessage('请选择是否建立团组织',2)
          return
        }else{
          if(this.form.league==1){
            if(!this.form.leagueManagersName){
              this.$qzfMessage('请输入团组织负责人姓名',2)
              return
            }else if(!this.form.leagueManagersSex){
              this.$qzfMessage('请选择性别',2)
              return
            }else if(!this.form.leaguePartyPhone){
              this.$qzfMessage('请输入办公电话',2)
              return
            }else if(!this.form.leaguePartyMobile){
              this.$qzfMessage('请输入手机',2)
              return
            }else if(!this.form.leaguePartySubjection){
              this.$qzfMessage('请选择团组织主管/隶属单位',2)
              return
            }else if(!this.form.leagueOrganizational){
              this.$qzfMessage('请选择团组织建制情况',2)
              return
            }else if(!this.form.leagueConstructionMode){
              this.$qzfMessage('请选择团组织组建方式',2)
              return
            }else if(!this.form.leagueMenberNum){
              this.$qzfMessage('请输入党员人数（含预备党员）',2)
              return
            }else if(!this.form.leagueStripling){
              this.$qzfMessage('请输入35岁以下青年人数',2)
              return
            }else if(!this.form.leagueBuildTime){
              this.$qzfMessage('请选择团组织组建时间',2)
              return
            }
          }else{
            if(!this.form.leagueMenberNum){
              this.$qzfMessage('请输入团员人数（含预备党员）',2)
              return
            }else if(!this.form.leagueStripling){
              this.$qzfMessage('请输入35岁以下青年人数',2)
              return
            }else if(!this.form.leagueUpcomingCondition){
              this.$qzfMessage('请选择团组织拟建制情况',2)
              return
            }else if(!this.form.leagueOrganizeWay){
              this.$qzfMessage('请选择团组织拟组建方式',2)
              return
            }
          }

        }
        }
      }
      this.form.leagueMenberNum = parseInt(this.form.leagueMenberNum)
      this.form.partyMemberNum = parseInt(this.form.partyMemberNum)
      let param = {
        tableName: "gsnb_party",
        query: this.form,
      };
      updateGsnb(param).then((res) => {
        if (res.data.msg == "success") {
          this.$qzfMessage("保存成功");
          this.getList();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  border: 1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  tr {
    padding: 0;
    margin: 0;
  }
  td {
    border-right: 1px solid #c2c2c2;
    border-bottom: 1px solid #c2c2c2;
    height: 35px;
    line-height: 35px;
    padding: 0 6px;
    color: #333;
    font-size: 13px;
  }
}
.right {
  text-align: right;
  background-color: #ecf7f9;
}
.center {
  text-align: center;
  background-color: #ecf7f9;
}
</style>

<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      v-model="dialogFormVisible"
      title="企业基本信息"
      width="1100px"
      destroy-on-close
      center
    >
      <el-scrollbar :maxHeight="contentStyleObj">
        <el-form
          :model="form"
          label-width="170px"
          :inline="true"
          size="small"
          v-loading="loading"
          :rules="rules"
          ref="ruleForm"
        >
          <div>
            <el-form-item label="报告年度">
              <el-input v-model="form.year" disabled style="width: 320px;" />
            </el-form-item>
          </div>
          <el-form-item label="企业名称">
            <el-input v-model="form.name" disabled style="width: 320px;" />
          </el-form-item>
          <el-form-item label="统一社会信用代码/注册号">
            <el-input v-model="form.taxNo" disabled style="width: 320px;" />
          </el-form-item>
          <el-form-item label="企业通讯地址" prop="address">
            <el-input v-model="form.address" style="width: 320px;" />
          </el-form-item>
          <el-form-item label="邮政编码" prop="postalCode">
            <el-input v-model="form.postalCode" style="width: 320px;" />
          </el-form-item>
          <el-form-item label="企业联系电话" prop="phone">
            <el-input v-model="form.phone" style="width: 320px;" />
          </el-form-item>
          <el-form-item label="电子邮箱" prop="email">
            <el-input v-model="form.email" style="width: 320px;" />
          </el-form-item>
          <div>
            <el-form-item label="企业主营业务活动" prop="business">
              <el-input
                v-model="form.business"
                type="textarea"
                style="width: 842px;"
              />
            </el-form-item>
          </div>
          <div v-if="type != '农村合作社'">
            <el-form-item label="经营者姓名" prop="proprietorName">
              <el-input v-model="form.proprietorName" style="width: 320px;" />
            </el-form-item>
            <el-form-item label="资金数额" prop="fundsAmount">
              <el-input v-model="form.fundsAmount" style="width: 320px;">
                <template #append>万元</template>
              </el-input>
            </el-form-item>
          </div>
          <div v-else>
            <el-form-item label="成员">
              共<el-input style="width:100px;margin:0 3px" v-model="form.allPeople"></el-input
              >人，其中农民人数：<el-input v-model="form.peasant"
                style="width:100px;margin:0 3px"
              ></el-input
              >人。 本年度新增<el-input v-model="form.newlyPeople"
                style="width:100px;margin:0 3px"
              ></el-input
              >人，退出<el-input style="width:100px;margin:0 3px" v-model="form.quitPeople"></el-input
              >人。
            </el-form-item>
          </div>
          <el-form-item label="从业人数" prop="peopleNum">
            <el-input v-model.number="form.peopleNum" style="width: 320px;">
              <template #append>
                <el-radio-group v-model="form.peopleNumShow">
                  <el-radio :label="0">公示</el-radio>
                  <el-radio :label="1">不公示</el-radio>
                </el-radio-group>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item label="(其中女性从业人数)" prop="womanNum">
            <el-input v-model.number="form.womanNum" style="width: 320px;">
              <template #append>
                <el-radio-group v-model="form.womanNumShow">
                  <el-radio :label="0">公示</el-radio>
                  <el-radio :label="1">不公示</el-radio>
                </el-radio-group>
              </template>
            </el-input>
          </el-form-item>
          <div v-if="type != '农村合作社'">
            <el-form-item label="企业经营状态">
              <el-select v-model="form.status" style="width: 320px;margin: 0;">
                <el-option label="开业" value="开业" />
                <el-option label="歇业" value="歇业" />
                <el-option label="清算" value="清算" />
              </el-select>
            </el-form-item>
            <el-form-item label="企业控股情况">
              <el-select v-model="form.holding" style="margin-left: 0;">
                <el-option label="国有控股" value="国有控股" />
                <el-option label="集体控股" value="集体控股" />
                <el-option label="私有控股" value="私有控股" />
                <el-option label="港澳台商控股" value="港澳台商控股" />
                <el-option label="外商控股" value="外商控股" />
                <el-option label="其他" value="其他" />
              </el-select>
              <el-radio-group v-model="form.holdingShow">
                <el-radio :label="0">公示</el-radio>
                <el-radio :label="1">不公示</el-radio>
              </el-radio-group>
            </el-form-item>
            <div style="margin-bottom: 5px;">
              <el-form-item label="隶属企业名称" prop="subjection">
                <el-input v-model="form.subjection" style="width: 320px;" />
              </el-form-item>
              <el-form-item
                label="隶属企业统一社会信用代码/注册号"
                prop="subjectionTaxNo"
              >
                <el-input
                  v-model="form.subjectionTaxNo"
                  style="width: 320px;"
                />
              </el-form-item>
            </div>
          </div>

          <el-form-item
            label="是否有对外担保"
            v-if="type != '农村合作社'"
          >
            <div style="width: 315px;">
              <el-radio-group v-model="form.guarantee">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </div>
          </el-form-item>
          <el-form-item label="是否有网站或网店">
            <div style="width: 315px;">
              <el-radio-group v-model="form.website">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </div>
          </el-form-item>
          <div v-if="type != '农村合作社'">
            <el-form-item label="本年度是否发生股东股权转让">
              <div style="width: 315px;">
                <el-radio-group v-model="form.stockRightTransfer">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </div>
            </el-form-item>
            <el-form-item label="是否有投资信息或购买其他公司股权">
              <el-radio-group v-model="form.invest">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <div class="record">以下信息不公示</div>
            <el-form-item label="其中高校毕业人数">
              <el-input
                v-model.number="form.graduateProprietor"
                style="width: 421px;"
              >
                <template #prepend>经营者</template>
                <template #append>人</template>
              </el-input>
              <el-input
                v-model.number="form.graduateEmployee"
                style="width: 421px;margin-left: 3px;"
              >
                <template #prepend>雇员</template>
                <template #append>人</template>
              </el-input>
            </el-form-item>
            <el-form-item label="其中退役士兵人数">
              <el-input
                v-model.number="form.veteranProprietor"
                style="width: 421px;"
              >
                <template #prepend>经营者</template>
                <template #append>人</template>
              </el-input>
              <el-input
                v-model.number="form.veteranEmployee"
                style="width: 421px;margin-left: 3px;"
              >
                <template #prepend>雇员</template>
                <template #append>人</template>
              </el-input>
            </el-form-item>
            <el-form-item label="其中残疾人人数">
              <el-input
                v-model.number="form.disabledProprietor"
                style="width: 421px;"
              >
                <template #prepend>经营者</template>
                <template #append>人</template>
              </el-input>
              <el-input
                v-model.number="form.disabledEmployee"
                style="width: 421px;margin-left: 3px;"
              >
                <template #prepend>雇员</template>
                <template #append>人</template>
              </el-input>
            </el-form-item>
            <el-form-item label="其中失业人员再就业人数">
              <el-input
                v-model.number="form.employmentProprietor"
                style="width: 421px;"
              >
                <template #prepend>经营者</template>
                <template #append>人</template>
              </el-input>
              <el-input
                v-model.number="form.employmentEmployee"
                style="width: 421px;margin-left: 3px;"
              >
                <template #prepend>雇员</template>
                <template #append>人</template>
              </el-input>
            </el-form-item>
          </div>
        </el-form>
      </el-scrollbar>

      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogFormVisible = false" size="small"
            >关闭</el-button
          >
          <el-button type="primary" @click="save" size="small">
            保存
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { getCompanyInfo, updateGsnb } from "@/api/gsnb";

export default {
  name: "ComBasicTable",
  props: {
    startAccountPeriod: {
      default: "",
      type: String,
    },
    comId: {
      default: 0,
      type: Number,
    },
  },
  data() {
    return {
      dialogFormVisible: false,
      form: {},
      contentStyleObj: {},
      loading: false,
      rules: {
        address: [
          { required: true, message: "请输入企业通讯地址", trigger: "blur" },
        ],
        postalCode: [
          { required: true, message: "请输入邮政编码", trigger: "blur" },
        ],
        phone: [
          { required: true, message: "请输入企业联系电话", trigger: "blur" },
        ],
        email: [{ required: true, message: "请输入电子邮箱", trigger: "blur" }],
        business: [
          {
            required: true,
            message: "请输入企业主营业务活动",
            trigger: "blur",
          },
        ],
        peopleNum: [
          { required: true, message: "请输入从业人数", trigger: "blur" },
        ],
        womanNum: [
          {
            required: true,
            message: "请输入其中女性从业人数",
            trigger: "blur",
          },
        ],
        // subjection: [
        //   { required: true, message: "请输入隶属企业名称", trigger: "blur" },
        // ],
        // subjectionTaxNo: [
        //   {
        //     required: true,
        //     message: "请输入隶属企业统一社会信用代码/注册号",
        //     trigger: "blur",
        //   },
        // ],
      },
      type: "",
    };
  },

  mounted() {
    this.contentStyleObj = this.$getHeight(280);
  },

  methods: {
    getParams(flag) {
      this.type = flag;
      this.dialogFormVisible = true;
      this.getList();
    },
    getList() {
      this.loading = true;
      getCompanyInfo({
        comId: this.comId,
        year: this.startAccountPeriod,
        tableName: "gsnb_base",
      }).then((res) => {
        this.loading = false;
        if (res.data.msg == "success") {
          this.form = res.data.data.data;
        }
      });
    },
    save() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          let param = {
            tableName: "gsnb_base",
            query: this.form,
          };
          updateGsnb(param).then((res) => {
            if (res.data.msg == "success") {
              this.$qzfMessage("保存成功");
              this.getList();
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.el-radio) {
  margin-right: 5px;
}
.record {
  font-size: 14px;
  color: #17a2b8;
  margin: 10px 0;
}
.record::before {
  content: "";
  background: var(--themeColor, #17a2b8);
  display: inline-block;
  width: 3px;
  height: 13px;
  position: relative;
  margin-right: 10px;
}
:deep(.el-form-item__label) {
  line-height: 21px !important;
}
</style>

<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      v-model="dialogFormVisible"
      title="海关报关信息"
      width="900px"
      destroy-on-close
      center
    >
      <el-scrollbar :style="{ height: contentStyleObj }" v-loading="loading">
        <div class="record" style="margin-top: 0;">海关注册信息</div>
        <el-form
          :model="form"
          label-width="160px"
          :inline="true"
          size="small"
          :rules="rules"
          ref="ruleForm"
        >
          <el-form-item label="英文名称">
            <el-input v-model="form.enName" style="width: 220px;" />
          </el-form-item>
          <el-form-item label="英文地址">
            <el-input v-model="form.enAddress" style="width: 220px;" />
          </el-form-item>
          <el-form-item
            label="跨境贸易电子商务企业类型或非跨境贸易电子商务企业"
          >
            <el-checkbox-group v-model="form.types2">
              <el-checkbox label="电子商务企业" />
              <el-checkbox label="电子商务交易平台" />
              <el-checkbox label="物流企业" />
              <el-checkbox label="支付企业" />
              <el-checkbox label="监管场所运营人" />
              <el-checkbox label="非跨境贸易电子商务企业" />
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="开户银行">
            <el-input v-model="form.bank" style="width: 220px;" />
          </el-form-item>
          <el-form-item label="开户账号">
            <el-input v-model="form.bankNum" style="width: 220px;" />
          </el-form-item>
          <el-form-item label="特殊贸易区域">
            <el-select
              v-model="form.specialTradeArea"
              clearable
              style="width: 220px;margin: 0;"
            >
              <el-option label="非特殊区域" value="非特殊区域"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="经济区划">
            <el-select
              v-model="form.economicsDivision"
              clearable
              style="width: 220px;margin: 0;"
            >
              <el-option
                v-for="item in jjqhOptiopns"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <table class="content" cellspacing="0" width="100%">
          <tr>
            <td class="center" style="width: 15%;" rowspan="6">关务负责人</td>
            <td class="right" style="width:30%;">姓名</td>
            <td style="width:55%;">
              <el-input v-model="form.functionaryName" size="small"> </el-input>
            </td>
          </tr>
          <tr>
            <td class="right">身份证件类型</td>
            <td>
              <el-select
                size="small"
                v-model="form.functionaryIDType"
                clearable
                style="width: 100%;margin: 0;"
              >
                <el-option
                  v-for="item in idTypeOptiopns"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </td>
          </tr>
          <tr>
            <td class="right">身份证件号码</td>
            <td>
              <el-input v-model="form.functionaryIDNo" size="small"> </el-input>
            </td>
          </tr>
          <tr>
            <td class="right">固定电话</td>
            <td>
              <el-input v-model="form.functionaryTel" size="small"> </el-input>
            </td>
          </tr>
          <tr>
            <td class="right">移动电话</td>
            <td>
              <el-input v-model="form.functionaryPhone" size="small">
              </el-input>
            </td>
          </tr>
          <tr>
            <td class="right">电子邮箱</td>
            <td>
              <el-input v-model="form.functionaryEmail" size="small">
              </el-input>
            </td>
          </tr>
        </table>

        <table
          class="content"
          cellspacing="0"
          width="100%"
          style="margin: 10px 0;"
        >
          <tr>
            <td class="center" style="width: 15%;" rowspan="5">
              海关业务负责人
            </td>
            <td class="right" style="width:30%;">姓名</td>
            <td style="width:55%;">
              <el-input v-model="form.contactsName" size="small"> </el-input>
            </td>
          </tr>
          <tr>
            <td class="right">固定电话</td>
            <td>
              <el-input v-model="form.contactsTel" size="small"> </el-input>
            </td>
          </tr>
          <tr>
            <td class="right">移动电话</td>
            <td>
              <el-input v-model="form.contactsPhone" size="small"> </el-input>
            </td>
          </tr>
          <tr>
            <td class="right">电子邮箱</td>
            <td>
              <el-input v-model="form.contactsEmail" size="small"> </el-input>
            </td>
          </tr>
          <tr>
            <td class="right">传真</td>
            <td>
              <el-input v-model="form.contactsFax" size="small"> </el-input>
            </td>
          </tr>
        </table>

        <el-form
          :model="form"
          label-width="160px"
          :inline="true"
          size="small"
          :rules="rules"
          ref="ruleForm"
        >
          <el-form-item label="经营场所性质">
            <el-select
              v-model="form.place"
              clearable
              style="width: 220px;margin: 0;"
            >
              <el-option label="自有" value="自有"></el-option>
              <el-option label="租赁" value="租赁"></el-option>
              <el-option label="借用" value="借用"></el-option>
              <el-option label="其他" value="其他"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否上市公司">
            <el-radio-group v-model="form.isMarketing">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <div>
            <el-form-item label="是否实行会计电算化">
              <el-radio-group v-model="form.isComputerization">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
          <el-form-item label="记账方式">
            <el-select
              v-model="form.accountingMethod"
              clearable
              style="width: 220px;margin: 0;"
            >
              <el-option label="自理记帐" value="自理记帐"></el-option>
              <el-option label="委托代理记帐" value="委托代理记帐"></el-option>
            </el-select>
          </el-form-item>
          <div class="record">经营补充信息(币种：人民币)</div>
          <el-form-item label="最近三年是否连续亏损">
            <el-radio-group v-model="form.isLoss">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
        <table class="content" cellspacing="0" width="100%">
          <tr>
            <td class="right" style="width: 15%;">存货</td>
            <td style="width:35%;">
              <el-input v-model="form.stock" size="small">
                <template #append>
                  <span style="color: red;">万元</span>
                </template>
              </el-input>
            </td>
            <td style="width:15%;" class="right">
              流动负债
            </td>
            <td style="width:35%;">
              <el-input v-model="form.liabilities" size="small">
                <template #append>
                  <span style="color: red;">万元</span>
                </template>
              </el-input>
            </td>
          </tr>
          <tr>
            <td class="right">流动资产</td>
            <td>
              <el-input v-model="form.asset" size="small">
                <template #append>
                  <span style="color: red;">万元</span>
                </template>
              </el-input>
            </td>
            <td class="right">
              经营现金净流量
            </td>
            <td>
              <el-input v-model="form.cash" size="small">
                <template #append>
                  <span style="color: red;">万元</span>
                </template>
              </el-input>
            </td>
          </tr>
          <tr>
            <td class="right">营业利润</td>
            <td>
              <el-input v-model="form.profit" size="small">
                <template #append>
                  <span style="color: red;">万元</span>
                </template>
              </el-input>
            </td>
            <td class="right">
              年初所有者权益
            </td>
            <td>
              <el-input v-model="form.rights" size="small">
                <template #append>
                  <span style="color: red;">万元</span>
                </template>
              </el-input>
            </td>
          </tr>
        </table>

        <div class="record">企业自律管理情况</div>
        <table class="content" cellspacing="0" width="100%">
          <tr>
            <td class="right" style="width: 50%;">年度内是否开展内外部审计</td>
            <td style="width:50%;">
              <el-radio-group v-model="form.audit" size="small">
                <el-radio :label="1">内部会计</el-radio>
                <el-radio :label="2">外部会计</el-radio>
              </el-radio-group>
            </td>
          </tr>
          <tr>
            <td class="right">
              在内外部审计等企业自律管理中是否发现一般贸易业务、加工贸易及保税业务、减免税业务或其他进出口业务存在违反海关管理规定的问题。
            </td>
            <td>
              <el-radio-group v-model="form.stipulation" size="small">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </td>
          </tr>
          <tr>
            <td class="right">具体问题</td>
            <td>
              <el-input v-model="form.issue" size="small" type="textarea" />
            </td>
          </tr>
        </table>
      </el-scrollbar>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogFormVisible = false" size="small"
            >关闭</el-button
          >
          <el-button type="primary" @click="save" size="small">
            保存
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { getCompanyInfo, updateGsnb } from "@/api/gsnb";

export default {
  name: "hgbgxxTable",
  props: {
    startAccountPeriod: {
      default: "",
      type: String,
    },
    comId: {
      default: 0,
      type: Number,
    },
  },
  data() {
    return {
      dialogFormVisible: false,
      form: {},
      loading: false,
      contentStyleObj: {},
      jjqhOptiopns: [
        { label: "经济特区", value: "经济特区" },
        { label: "经济技术开发全区", value: "经济技术开发全区" },
        { label: "高新技术产业开发区", value: "高新技术产业开发区" },
        { label: "保税区", value: "保税区" },
        { label: "出口加工区", value: "出口加工区" },
        { label: "保税港区/综合保税区", value: "保税港区/综合保税区" },
        { label: "保税物流园区", value: "保税物流园区" },
        { label: "综合实验区", value: "综合实验区" },
        { label: "国际边境合作中心", value: "国际边境合作中心" },
        { label: "保税物流中心", value: "保税物流中心" },
        { label: "一般经济区域", value: "一般经济区域" },
      ],
      idTypeOptiopns: [
        {
          label: "中华人民共和国居民身份证",
          value: "中华人民共和国居民身份证",
        },
        { label: "中华人民共和国军官证", value: "中华人民共和国军官证" },
        { label: "中华人民共和国警官证", value: "中华人民共和国警官证" },
        { label: "外国（地区）护照", value: "外国（地区）护照" },
        { label: "香港特别行政区护照", value: "香港特别行政区护照" },
        {
          label: "香港（永久性）居民身份证",
          value: "香港（永久性）居民身份证",
        },
        { label: "澳门特别行政区护照", value: "澳门特别行政区护照" },
        {
          label: "澳门（永久性）居民身份证",
          value: "澳门（永久性）居民身份证",
        },
        { label: "港澳居民来往内地通行证", value: "港澳居民来往内地通行证" },
        { label: "台湾居民身份证", value: "台湾居民身份证" },
        { label: "台湾居民来往大陆通行证", value: "台湾居民来往大陆通行证" },
        { label: "台湾农民身份有关证明", value: "台湾农民身份有关证明" },
        { label: "户口薄", value: "户口薄" },
        { label: "其他有效身份证件", value: "其他有效身份证件" },
      ],
    };
  },

  mounted() {
    this.contentStyleObj = this.$getHeight(230);
  },

  methods: {
    getParams() {
      this.dialogFormVisible = true;
      this.getList();
    },
    getList() {
      this.loading = true;
      getCompanyInfo({
        comId: this.comId,
        year: this.startAccountPeriod,
        tableName: "gsnb_customs",
      }).then((res) => {
        this.loading = false;
        if (res.data.msg == "success" && res.data.data.data.id) {
          this.form = res.data.data.data;
        } else {
          this.$qzfMessage("若需填写本报表，请重新采集", 1);
        }
      });
    },
    save() {
      let param = {
        tableName: "gsnb_customs",
        query: this.form,
      };
      updateGsnb(param).then((res) => {
        if (res.data.msg == "success") {
          this.$qzfMessage("保存成功");
          this.getList();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  border: 1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  tr {
    padding: 0;
    margin: 0;
  }
  td {
    border-right: 1px solid #c2c2c2;
    border-bottom: 1px solid #c2c2c2;
    height: 35px;
    line-height: 35px;
    padding: 0 6px;
    color: #333;
    font-size: 13px;
  }
}
.right {
  text-align: right;
  background-color: #ecf7f9;
}
.center {
  text-align: center;
  background-color: #ecf7f9;
}
.record {
  font-size: 14px;
  color: #17a2b8;
  margin: 10px 0;
}
.record::before {
  content: "";
  background: var(--themeColor, #17a2b8);
  display: inline-block;
  width: 3px;
  height: 13px;
  position: relative;
  margin-right: 10px;
}
:deep(.el-form-item__label) {
  line-height: 21px !important;
}
</style>

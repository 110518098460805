<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      v-model="dialogFormVisible"
      title="社保信息"
      width="1000px"
      destroy-on-close
      center
    >
      <el-scrollbar :style="{ height: contentStyleObj }" v-loading="loading">
        <!-- 辽宁 -->
        <div v-if="district =='liaoning'">
          <table class="content" cellspacing="0" width="100%">
            <tr>
              <td  style="width:12%;" class="list-td" rowspan="2">
                参保险种类型
              </td>
              <td style="width:12%;" class="list-td">
                <div>全年累计缴费人次</div>
                <div style="margin-top: -7%;">（各月人数之和）</div>
              </td>
              <td style="width:12%;" class="list-td">
                <div>期末参保人数</div>
                <div style="margin-top: -7%;">（12月份人数）</div>
              </td>
              <td style="width:12%;" class="list-td">
                <div>全年缴费基数合计</div>
                <div style="margin-top: -7%;">（单位：万元）</div>
              </td>
              <td style="width:12%;" class="list-td">
                <div>本期实际缴费金额</div>
                <div style="margin-top: -7%;">（单位：万元）</div>
              </td>
              <td style="width:12%;" class="list-td">
                <div>单位累计欠缴金额</div>
                <div style="margin-top: -7%;">（单位：万元）</div>
              </td>
            </tr>
            <tr>
              <td style="width:12%;color: red;" class="list-td">
                该项数据不公示
              </td>
              <td style="width:12%;color: red;" class="list-td">
                该项数据不公示
              </td>
              <td style="width:12%;" >
                <el-radio-group v-model="yearly_show">
                  <el-radio :label="1" style="margin-left: 8px;">公示</el-radio>
                  <el-radio :label="0" style="margin-left: -12px;">不公示</el-radio>
                </el-radio-group>
              </td>
              <td style="width:12%;">
                <el-radio-group v-model="current_show">
                  <el-radio :label="1" style="margin-left: 8px;">公示</el-radio>
                  <el-radio :label="0" style="margin-left: -12px;">不公示</el-radio>
                </el-radio-group>
              </td>
              <td style="width:12%;">
                <el-radio-group v-model="accumulated_show">
                  <el-radio :label="1" style="margin-left: 8px;">公示</el-radio>
                  <el-radio :label="0" style="margin-left: -12px;">不公示</el-radio>
                </el-radio-group>
              </td>
            </tr>
            <tr v-for="item in list" :key="item.id">
              <td style="width:12%;text-align: center;" class="list-center">
                <span v-if="item.type == '1'">城镇职工基本保险</span>
                <span v-if="item.type == '2'">失业保险</span>
                <span v-if="item.type == '3'">职工基本医疗保险保险</span>
                <span v-if="item.type == '4'">工伤保险</span>
                <span v-if="item.type == '5'">生育保险</span>
              </td>
              <td style="width:12%;text-align: center;" >
                <qzf-input v-model:num="item.totalAnnualPayers" class="filter-item" size="small" style="width: 100px" />
              </td>
              <td style="width:12%;text-align: center;">
                <qzf-input v-model:num="item.endInsuredNumber" class="filter-item" size="small" style="width: 100px" />
              </td>
              <td style="width:12%;text-align: center;">
                <qzf-input v-model:num="item.yearlyPaymentBasis" class="filter-item" size="small" style="width: 100px" />
              </td>
              <td style="width:12%;text-align: center;">
                <qzf-input v-model:num="item.currentActualAmount" class="filter-item" size="small" style="width: 100px" />
              </td>
              <td style="width:12%;text-align: center;">
                <qzf-input v-model:num="item.accumulatedUnpaidAmount" class="filter-item" size="small" style="width: 100px" />
              </td>
            </tr>
          </table>
        </div> 
        <!-- 非辽宁 -->
        <div v-else>
          <table class="content" cellspacing="0" width="100%">
            <tr>
              <td class="right" style="width:30%;">
                城镇职工基本医疗保险（单位：人）
              </td>
              <td style="width:25%;">
                <el-input v-model.number="form.endowment" size="small">
                </el-input>
              </td>
              <td style="width:20%;" class="right">
                失业保险（单位：人）
              </td>
              <td style="width:25%;">
                <el-input v-model.number="form.unemployment" size="small">
                </el-input>
              </td>
            </tr>
            <tr>
              <td class="right">
                职工基本医疗保险（单位：人）
              </td>
              <td>
                <el-input v-model.number="form.medical" size="small"> </el-input>
              </td>
              <td class="right">
                工伤保险（单位：人）
              </td>
              <td>
                <el-input v-model.number="form.employmentInjury" size="small">
                </el-input>
              </td>
            </tr>
            <tr>
              <td class="right">
                生育保险（单位：人）
              </td>
              <td>
                <el-input v-model.number="form.birth" size="small"> </el-input>
              </td>
              <td class="right"></td>
              <td></td>
            </tr>
          </table>

          <table
            class="content"
            cellspacing="0"
            width="100%"
            style="margin-top: 20px;"
          >
            <tr>
              <td rowspan="5" class="center" style="width: 20%;">单位缴费基数</td>
              <td style="width: 30%;" class="right">
                单位参加城镇职工基本养老保险缴费基数
              </td>
              <td style="width: 30%;">
                <el-input v-model="form.baseEndowment" size="small">
                  <template #append>
                    <span style="color: red;">万元</span>
                  </template>
                </el-input>
              </td>
              <td rowspan="4" style="width: 20%;">
                <el-radio-group v-model="form.baseShow" size="small">
                  <el-radio :label="0">选择公示</el-radio>
                  <el-radio :label="1">选择不公示</el-radio>
                </el-radio-group>
              </td>
            </tr>
            <tr>
              <td class="right">
                单位参加失业保险缴费基数
              </td>
              <td>
                <el-input v-model="form.baseUnemployment" size="small">
                  <template #append>
                    <span style="color: red;">万元</span>
                  </template>
                </el-input>
              </td>
            </tr>
            <tr>
              <td class="right">
                单位参加职工基本医疗保险缴费基数
              </td>
              <td>
                <el-input v-model="form.baseMedical" size="small">
                  <template #append>
                    <span style="color: red;">万元</span>
                  </template>
                </el-input>
              </td>
            </tr>
            <tr>
              <td class="right">
                单位参加工伤保险缴费基数
              </td>
              <td>
                <el-input v-model="form.baseEmployment" size="small">
                  <template #append>
                    <span style="color: red;">万元</span>
                  </template>
                </el-input>
              </td>
            </tr>
            <tr>
              <td class="right">
                单位参加生育保险缴费基数
              </td>
              <td>
                <el-input v-model="form.baseBirth" size="small">
                  <template #append>
                    <span style="color: red;">万元</span>
                  </template>
                </el-input>
              </td>
            </tr>
            <tr>
              <td rowspan="5" class="center" style="width: 20%;">
                本期实际缴费金额
              </td>
              <td style="width: 30%;" class="right">
                参加城镇职工基本养老保险本期实际缴费金额
              </td>
              <td style="width: 30%;">
                <el-input v-model="form.contributedEndowment" size="small">
                  <template #append>
                    <span style="color: red;">万元</span>
                  </template>
                </el-input>
              </td>
              <td rowspan="5" style="width: 20%;">
                <el-radio-group v-model="form.contributedShow" size="small">
                  <el-radio :label="0">选择公示</el-radio>
                  <el-radio :label="1">选择不公示</el-radio>
                </el-radio-group>
              </td>
            </tr>
            <tr>
              <td class="right">
                参加失业保险本期实际缴费金额
              </td>
              <td>
                <el-input v-model="form.contributedUnemployment" size="small">
                  <template #append>
                    <span style="color: red;">万元</span>
                  </template>
                </el-input>
              </td>
            </tr>
            <tr>
              <td class="right">
                参加职工基本医疗保险本期实际缴费金额
              </td>
              <td>
                <el-input v-model="form.contributedMedical" size="small">
                  <template #append>
                    <span style="color: red;">万元</span>
                  </template>
                </el-input>
              </td>
            </tr>
            <tr>
              <td class="right">
                参加工伤保险本期实际缴费金额
              </td>
              <td>
                <el-input v-model="form.contributedEmploymentInjury" size="small">
                  <template #append>
                    <span style="color: red;">万元</span>
                  </template>
                </el-input>
              </td>
            </tr>
            <tr>
              <td class="right">
                参加生育保险本期实际缴费金额
              </td>
              <td>
                <el-input v-model="form.contributedBirth" size="small">
                  <template #append>
                    <span style="color: red;">万元</span>
                  </template>
                </el-input>
              </td>
            </tr>
            <tr>
              <td rowspan="5" class="center" style="width: 20%;">
                单位累计欠缴金额
              </td>
              <td style="width: 30%;" class="right">
                单位参加城镇职工基本养老保险累计欠缴金额
              </td>
              <td style="width: 30%;">
                <el-input v-model="form.arrearsEndowment" size="small">
                  <template #append>
                    <span style="color: red;">万元</span>
                  </template>
                </el-input>
              </td>
              <td rowspan="5" style="width: 20%;">
                <el-radio-group v-model="form.arrearsShow" size="small">
                  <el-radio :label="0">选择公示</el-radio>
                  <el-radio :label="1">选择不公示</el-radio>
                </el-radio-group>
              </td>
            </tr>
            <tr>
              <td class="right">
                单位参加失业保险累计欠缴金额
              </td>
              <td>
                <el-input v-model="form.arrearsUnemployment" size="small">
                  <template #append>
                    <span style="color: red;">万元</span>
                  </template>
                </el-input>
              </td>
            </tr>
            <tr>
              <td class="right">
                单位参加职工基本医疗保险累计欠缴金额
              </td>
              <td>
                <el-input v-model="form.arrearsMedical" size="small">
                  <template #append>
                    <span style="color: red;">万元</span>
                  </template>
                </el-input>
              </td>
            </tr>
            <tr>
              <td class="right">
                单位参加工伤保险累计欠缴金额
              </td>
              <td>
                <el-input v-model="form.arrearsEmploymentInjury" size="small">
                  <template #append>
                    <span style="color: red;">万元</span>
                  </template>
                </el-input>
              </td>
            </tr>
            <tr>
              <td class="right">
                单位参加生育保险累计欠缴金额
              </td>
              <td>
                <el-input v-model="form.arrearsBirth" size="small">
                  <template #append>
                    <span style="color: red;">万元</span>
                  </template>
                </el-input>
              </td>
            </tr>
          </table>

        </div>
      </el-scrollbar>

      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogFormVisible = false" size="small"
            >关闭</el-button
          >
          <el-button type="primary" @click="save" size="small">
            保存
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { getCompanyInfo, updateGsnb,} from "@/api/gsnb";

export default {
  name: "sbxxTable",    
  props: {
    startAccountPeriod: {
      default: "",
      type: String,
    },
    comId: {
      default: 0,
      type: Number,
    },
  },
  data() {
    return {
      dialogFormVisible: false,
      form: {},
      contentStyleObj: {},
      loading:false,
      district:'',
      accumulated_show:null,
      current_show:null,
      yearly_show:null,
    };
  },

  mounted() {
    this.contentStyleObj = this.$getHeight(380);
  },

  methods: {
    getParams(val) {
      this.district = val.district
      this.dialogFormVisible = true;
      this.getList();
    },
    getList() {
      this.loading = true
      if(this.district=="liaoning"){
        getCompanyInfo({
          comId: this.comId,
          year: this.startAccountPeriod,
          tableName: "gsnb_socialSecurity_liaoning",
        }).then((res) => {
          this.loading = false
          if (res.data.msg == "success") {
            this.list = res.data.data.data;
            this.accumulated_show = res.data.data.accumulated_show
            this.current_show = res.data.data.current_show
            this.yearly_show = res.data.data.yearly_show
          }
        })
      }else{
        getCompanyInfo({
          comId: this.comId,
          year: this.startAccountPeriod,
          tableName: "gsnb_socialSecurity",
        }).then((res) => {
          this.loading = false
          if (res.data.msg == "success") {
            this.form = res.data.data.data;
          }
        });
      }
    },
    save() {
      if(this.district =='liaoning'){
        let querys = {
          accumulated_show:this.accumulated_show,
          current_show:this.current_show,
          yearly_show:this.yearly_show,
          data:this.list
        };
        let param = {
          tableName: "gsnb_socialSecurity_liaoning",
          query: querys,
        };
        updateGsnb(param).then((res) => {
          if (res.data.msg == "success") {
            this.$qzfMessage("保存成功");
            this.getList();
          }
        });
      }else{
        this.form.baseEndowment = this.form.baseEndowment ? Number(this.form.baseEndowment) : 0
        this.form.baseUnemployment = this.form.baseUnemployment ? Number(this.form.baseUnemployment) : 0
        this.form.baseMedical = this.form.baseMedical ? Number(this.form.baseMedical) : 0
        this.form.baseBirth = this.form.baseBirth ? Number(this.form.baseBirth) : 0
        this.form.baseEmployment = this.form.baseEmployment ? Number(this.form.baseEmployment) : 0

        this.form.contributedEndowment = this.form.contributedEndowment ? Number(this.form.contributedEndowment) : 0
        this.form.contributedEmploymentInjury = this.form.contributedEmploymentInjury ? Number(this.form.contributedEmploymentInjury) : 0
        this.form.contributedBirth = this.form.contributedBirth ? Number(this.form.contributedBirth) : 0
        this.form.contributedMedical = this.form.contributedMedical ? Number(this.form.contributedMedical) : 0
        this.form.contributedUnemployment = this.form.contributedUnemployment ? Number(this.form.contributedUnemployment) : 0

        this.form.arrearsBirth = this.form.arrearsBirth ? Number(this.form.arrearsBirth) : 0
        this.form.arrearsEmploymentInjury = this.form.arrearsEmploymentInjury ? Number(this.form.arrearsEmploymentInjury) : 0
        this.form.arrearsEndowment = this.form.arrearsEndowment ? Number(this.form.arrearsEndowment) : 0
        this.form.arrearsMedical = this.form.arrearsMedical ? Number(this.form.arrearsMedical) : 0
        this.form.arrearsUnemployment = this.form.arrearsUnemployment ? Number(this.form.arrearsUnemployment) : 0
        let param = {
          tableName: "gsnb_socialSecurity",
          query: this.form,
        };
        updateGsnb(param).then((res) => {
          if (res.data.msg == "success") {
            this.$qzfMessage("保存成功");
            this.getList();
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  border: 1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  tr {
    padding: 0;
    margin: 0;
  }
  td {
    border-right: 1px solid #c2c2c2;
    border-bottom: 1px solid #c2c2c2;
    height: 35px;
    line-height: 35px;
    padding: 0 6px;
    color: #333;
    font-size: 13px;
  }
}
.right {
  text-align: right;
  background-color: #ecf7f9;
}
.center {
  text-align: center;
  background-color: #ecf7f9;
}
.list-td{
  background-color: #ECF7F9;
  text-align: center;
}
.lisy-center{
  text-align: center;
}
</style>

import request from '@/utils/request'

// 工商年报取数
export function createGsnb(data) {
  return request({
    url: 'api/v2/gsnb/createGsnb',
    method: 'post',
    data
  })
}

// info
export function getCompanyInfo(data) {
  return request({
    url: 'api/v2/gsnb/getCompanyInfo',
    method: 'post',
    data
  })
}

// 保存
export function updateGsnb(data) {
  return request({
    url: 'api/v2/gsnb/updateGsnb',
    method: 'post',
    data
  })
}

//删除股东信息
export function deleteGsnb(data) {
  return request({
    url: 'api/v2/gsnb/deleteGsnb',
    method: 'post',
    data
  })
}


//工商年报list
export function getGsnbList(data) {
  return request({
    url: 'api/v2/gsnb/getGsnbList',
    method: 'post',
    data
  })
}

//个独汇算清缴List
export function getGdhsqjList(data) {
  return request({
    url: 'api/v2/gdhsqj/getGdhsqjList',
    method: 'post',
    data
  })
}


//个独汇算清缴查看
export function getGdInfo(data) {
  return request({
    url: 'api/v2/gdhsqj/getCompanyInfo',
    method: 'post',
    data
  })
}

//个独汇算清缴修改
export function updateGdhsqj(data) {
  return request({
    url: 'api/v2/gdhsqj/updateGdhsqj',
    method: 'post',
    data
  })
}

//个独汇算清缴取数
export function gdhsqjSave(data) {
  return request({
    url: 'api/v2/gdhsqj/gdhsqjSave',
    method: 'post',
    data
  })
}

//个独汇算清缴添加人员
export function saveGdhsqjEmp(data) {
  return request({
    url: 'api/v2/gdhsqj/saveGdhsqjEmp',
    method: 'post',
    data
  })
}

//个独汇算清缴删除人员
export function deleteGdhsqjEmp(data) {
  return request({
    url: 'api/v2/gdhsqj/deleteGdhsqjEmp',
    method: 'post',
    data
  })
}
<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      v-model="dialogFormVisible"
      title="资产状况信息"
      width="900px"
      destroy-on-close
      center
    >
      <table class="content" cellspacing="0" width="100%" v-loading="loading" v-if="accountSystem != '农村合作社'">
        <tr>
          <td class="right" style="width:20%;">资产总额</td>
          <td style="width:50%;">
            <el-input v-model="form.assetTotal" size="small">
              <template #append>
                <span style="color: red;">万元</span>
              </template>
            </el-input>
          </td>
          <td style="width:30%;">
            <el-radio-group v-model="form.assetTotalShow" size="small">
              <el-radio :label="0">公示</el-radio>
              <el-radio :label="1">不公示</el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td class="right">所有者权益合计</td>
          <td>
            <el-input v-model="form.ownerEquity" size="small">
              <template #append>
                <span style="color: red;">万元</span>
              </template>
            </el-input>
          </td>
          <td>
            <el-radio-group v-model="form.ownerEquityShow" size="small">
              <el-radio :label="0">公示</el-radio>
              <el-radio :label="1">不公示</el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td class="right">负债总额</td>
          <td>
            <el-input v-model="form.liabilitiesTotal" size="small">
              <template #append>
                <span style="color: red;">万元</span>
              </template>
            </el-input>
          </td>
          <td>
            <el-radio-group v-model="form.liabilitiesTotalShow" size="small">
              <el-radio :label="0">公示</el-radio>
              <el-radio :label="1">不公示</el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td class="right">营业总收入</td>
          <td>
            <el-input v-model="form.taking" size="small">
              <template #append>
                <span style="color: red;">万元</span>
              </template>
            </el-input>
          </td>
          <td>
            <el-radio-group v-model="form.takingShow" size="small">
              <el-radio :label="0">公示</el-radio>
              <el-radio :label="1">不公示</el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td class="right">其中主营业收入</td>
          <td>
            <el-input v-model="form.mainBusiness" size="small">
              <template #append>
                <span style="color: red;">万元</span>
              </template>
            </el-input>
          </td>
          <td>
            <el-radio-group v-model="form.mainBusinessShow" size="small">
              <el-radio :label="0">公示</el-radio>
              <el-radio :label="1">不公示</el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td class="right">利润总额</td>
          <td>
            <el-input v-model="form.profit" size="small">
              <template #append>
                <span style="color: red;">万元</span>
              </template>
            </el-input>
          </td>
          <td>
            <el-radio-group v-model="form.profitShow" size="small">
              <el-radio :label="0">公示</el-radio>
              <el-radio :label="1">不公示</el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td class="right">净利润</td>
          <td>
            <el-input v-model="form.retainedProfits" size="small">
              <template #append>
                <span style="color: red;">万元</span>
              </template>
            </el-input>
          </td>
          <td>
            <el-radio-group v-model="form.retainedProfitsShow" size="small">
              <el-radio :label="0">公示</el-radio>
              <el-radio :label="1">不公示</el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td class="right">纳税总额</td>
          <td>
            <el-input v-model="form.ratepaying" size="small">
              <template #append>
                <span style="color: red;">万元</span>
              </template>
            </el-input>
          </td>
          <td>
            <el-radio-group v-model="form.ratepayingShow" size="small">
              <el-radio :label="0">公示</el-radio>
              <el-radio :label="1">不公示</el-radio>
            </el-radio-group>
          </td>
        </tr>
      </table>
      <table class="content" cellspacing="0" width="100%" v-loading="loading" v-else>
        <tr>
          <td class="right" style="width:20%;">销售额或营业收入</td>
          <td style="width:50%;">
            <el-input v-model="form.taking" size="small">
              <template #append>
                <span style="color: red;">万元</span>
              </template>
            </el-input>
          </td>
          <td style="width:30%;">
            <el-radio-group v-model="form.takingShow" size="small">
              <el-radio :label="0">公示</el-radio>
              <el-radio :label="1">不公示</el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td class="right">盈余总额</td>
          <td>
            <el-input v-model="form.profit" size="small">
              <template #append>
                <span style="color: red;">万元</span>
              </template>
            </el-input>
          </td>
          <td>
            <el-radio-group v-model="form.profitShow" size="small">
              <el-radio :label="0">公示</el-radio>
              <el-radio :label="1">不公示</el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td class="right">纳税总额</td>
          <td>
            <el-input v-model="form.ratepaying" size="small">
              <template #append>
                <span style="color: red;">万元</span>
              </template>
            </el-input>
          </td>
          <td>
            <el-radio-group v-model="form.ratepayingShow" size="small">
              <el-radio :label="0">公示</el-radio>
              <el-radio :label="1">不公示</el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td class="right">获得政府扶持资金、补助</td>
          <td>
            <el-input v-model="form.retainedProfits" size="small">
              <template #append>
                <span style="color: red;">万元</span>
              </template>
            </el-input>
          </td>
          <td>
            <el-radio-group v-model="form.retainedProfitsShow" size="small">
              <el-radio :label="0">公示</el-radio>
              <el-radio :label="1">不公示</el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td class="right">金融贷款</td>
          <td>
            <el-input v-model="form.ownerEquity" size="small">
              <template #append>
                <span style="color: red;">万元</span>
              </template>
            </el-input>
          </td>
          <td>
            <el-radio-group v-model="form.ownerEquityShow" size="small">
              <el-radio :label="0">公示</el-radio>
              <el-radio :label="1">不公示</el-radio>
            </el-radio-group>
          </td>
        </tr>
      </table>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogFormVisible = false" size="small"
            >关闭</el-button
          >
          <el-button type="primary" @click="save" size="small">
            保存
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { getCompanyInfo, updateGsnb } from "@/api/gsnb";

export default {
  name: "zczkxxTable",
  props: {
    startAccountPeriod: {
      default: "",
      type: String,
    },
    comId: {
      default: 0,
      type: Number,
    },
  },
  data() {
    return {
      dialogFormVisible: false,
      form: {},
      loading: false,
      type: "",
    };
  },

  mounted() {},

  methods: {
    getParams(flag) {
      this.type = flag;
      this.dialogFormVisible = true;
      this.getList();
    },
    getList() {
      this.loading = true;
      getCompanyInfo({
        comId: this.comId,
        year: this.startAccountPeriod,
        tableName: "gsnb_asset",
      }).then((res) => {
        this.loading = false;
        if (res.data.msg == "success") {
          this.form = res.data.data.data;
        }
      });
    },
    save() {
      this.form.assetTotal = Number(this.form.assetTotal);
      this.form.liabilitiesTotal = Number(this.form.liabilitiesTotal);
      this.form.mainBusiness = Number(this.form.mainBusiness);
      this.form.ownerEquity = Number(this.form.ownerEquity);
      this.form.profit = Number(this.form.profit);
      this.form.ratepaying = Number(this.form.ratepaying);
      this.form.retainedProfits = Number(this.form.retainedProfits);
      this.form.taking = Number(this.form.taking);
      let param = {
        tableName: "gsnb_asset",
        query: this.form,
      };
      updateGsnb(param).then((res) => {
        if (res.data.msg == "success") {
          this.$qzfMessage("保存成功");
          this.getList();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  border: 1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  tr {
    padding: 0;
    margin: 0;
  }
  td {
    border-right: 1px solid #c2c2c2;
    border-bottom: 1px solid #c2c2c2;
    height: 35px;
    line-height: 35px;
    padding: 0 6px;
    color: #333;
    font-size: 13px;
  }
}
.right {
  text-align: right;
  background-color: #ecf7f9;
}
</style>
